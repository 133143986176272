<template>
  <div class="card">
    <div class="flex flex-column card-container green-container">
      <div class="flex align-items-center justify-content-center">
        <div
          class="flex flex-column card-container bg-blue-100 border-round"
          style="width: 100%; max-width: 800px; margin: 5px 1px"
        >
          <div
            class="flex align-items-center justify-content-center"
            style="margin: 10px"
          >
            <Dropdown
              v-if="availablePlayers.length > 0"
              v-model="selectedPlayer"
              :options="availablePlayers"
              :editable="false"
              optionLabel="representation"
              placeholder="Hier den Spieler auswählen"
              :filter="availablePlayers.length >= 10"
              @change="addSelectedPlayer"
              style="width: 300px; margin: 10px"
            />
            <h3 v-else>
              Kein Spieler im Verein ohne Mannschaftszuordnung oder keine Spielerzuordnung
              erlaubt
            </h3>
          </div>
          <h4 style="margin: 0px">
            Folgende Spieler werden mit Klick auf Abschließen hinzugefügt:
          </h4>
          <div
            class="flex align-items-center justify-content-center"
            style="margin: 5px 10px"
          >
            <DataTable
              class="p-datatable-sm"
              :value="selectedPlayers"
              stripedRows
              responsiveLayout="stack"
              breakpoint="550px"
              style="margin: 10px; width: 80%"
            >
              <Column field="name" header="Name"></Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    style="margin: 2px 2px"
                    icon="pi pi-times"
                    v-tooltip.top="'Spieler wieder entfernen'"
                    class="p-button-rounded p-button-warn mb2"
                    @click="removeSelectedPlayer(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../../../services/ServerConnection/FailureHandler";
import AbstractWizardPage from "../../AbstractWizardPage";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "AddPlayerPage1",
  extends: AbstractWizardPage,
  components: {
    Dropdown,
    Button,
    DataTable,
    Column,
  },
  data() {
    return {
      availablePlayers: [],
      selectedPlayers: [],
      selectedPlayer: null,
    };
  },
  created() {
    this.loadAvailablePlayers();
  },
  methods: {
    validate() {
      if (this.selectedPlayers && this.selectedPlayers.length > 0) {
        this.recordModel.selectedPlayers = this.selectedPlayers;
        return true;
      } else {
        return false;
      }
    },
    addSelectedPlayer() {
      this.selectedPlayers.push(this.selectedPlayer);
      this.availablePlayers = this.availablePlayers.filter(
        (p) => p.id != this.selectedPlayer.id
      );
      this.selectedPlayer = null;
      this.invalidState = this.selectedPlayers.length > 0;
    },
    removeSelectedPlayer(player) {
      this.selectedPlayers = this.selectedPlayers.filter((p) => p != player);
      this.availablePlayers.push(player);
      this.availablePlayers.sort((p1, p2) => p1.name.localeCompare(p2.name));
      this.invalidState = this.selectedPlayers.length > 0;
    },
    loadAvailablePlayers() {
      ServerConnection.get(
        "teamadmin/availablePlayers?teamId=" +
          this.recordModel.baseRecord.id +
          "&associationId=" +
          this.recordModel.baseRecord.association.id
      )
        .then((response) => response.data)
        .then((dataList) => {
          this.availablePlayers = dataList;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
