<template>
  <div class="flex align-items-center justify-content-center">
    <div
      class="flex flex-wrap card-container bg-blue-100 border-round"
      style="max-width: 800px; margin: 5px 1px"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="flex align-items-center justify-content-center" style="width: 100%">
          <div style="margin: 10px; width: 100%">
            <div style="font-size: 24px; margin: 10px">
              {{ recordModel.baseRecord.representation }}
            </div>
            <span style="font-size: 18px; text-decoration: underline; margin-bottom: 10px"
              >Soll das Heimrecht mit dem Rückspiel getauscht werden (nur möglich bei
              Hinspielen) oder das Heimrecht nur für dieses Spiel geändert werden?</span
            >
            <SelectButton
              style="margin-top: 25px"
              v-model="selectedOption"
              :options="selectOptions"
              optionDisabled="disabled"
              optionLabel="label"
              @change="updateSwitch"
            />
          </div>
        </div>

        <div class="flex align-items-center justify-content-center" style="width: 100%">
          <div style="margin: 10px; width: 100%">
            <label style="font-size: 18px; text-decoration: underline"
              >Bemerkungen/Begründungen für den Vertreter der gegnerischen Mannschaft
            </label>
            <Textarea
              v-model="recordModel.notes"
              :autoResize="false"
              style="width: 100%; height: 30vh; margin-top: 5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../../../services/ServerConnection/ServerConnection";
import AbstractWizardPage from "../../AbstractWizardPage";
import SelectButton from "primevue/selectbutton";
import Textarea from "primevue/textarea";
export default {
  name: "SwitchHomeGuestRequestPage1",
  extends: AbstractWizardPage,
  components: {
    SelectButton,
    Textarea,
  },
  data() {
    return {
      rematchGame: {},
      selectedOption: {},
      selectOptions: [
        {
          value: "yes",
          label: "Heimrecht mit Rückspiel tauschen",
          disabled: false,
        },
        {
          value: "no",
          label: "Heimrecht für dieses Spiel ändern",
          disabled: false,
        },
      ],
    };
  },
  computed: {},
  created() {
    this.loadRematch();
  },
  updated() {
    this.loadRematch();
  },
  methods: {
    loadRematch() {
      if (!this.rematchGame || !this.rematchGame.id) {
        ServerConnection.get("data/game/" + this.recordModel.baseRecord.id + "/rematch")
          .then((resp) => {
            this.rematchGame = resp.data;

            if (this.isRematchGame()) {
              this.selectOptions
                .filter((opt) => opt.value == "yes")
                .forEach((opt) => (opt.disabled = true));
              this.selectedOption = this.selectOptions[1];
              this.recordModel.switchRematch = false;
            } else {
              this.selectedOption = this.selectOptions[0];
              this.recordModel.switchRematch = true;
            }
          })
          .catch((error) => {
            console.error("Error saving the record", error);
          });
      }
    },
    isRematchGame() {
      if (
        this.rematchGame &&
        this.rematchGame.id &&
        this.recordModel.baseRecord &&
        this.recordModel.baseRecord.id
      ) {
        return (
          this.rematchGame.gameDay.numberOfGameDay < this.recordModel.numberOfGameDay
        );
      }
      return false;
    },
    updateSwitch() {
      this.recordModel.switchRematch =
        this.selectedOption &&
        this.selectedOption.value &&
        this.selectedOption.value == "yes";
    },
    validate() {
      return this.selectedOption && this.selectedOption.value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
