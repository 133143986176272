<template>
  <!--
  <div style="margin: 22px 2px">
    <i
      class="pi pi-eye"
      style="font-size: 50px; padding: 5px 25px"
      v-badge.danger="2"
    ></i>
    <i
      class="pi pi-bell"
      style="font-size: 50px; padding: 5px 25px"
      v-badge.warning="5"
    ></i>
    <i
      class="pi pi-info-circle"
      style="font-size: 50px; padding: 5px 25px"
      v-badge.info="21"
    ></i>
  </div>
  -->
  <div style="padding: 2px 10px; margin: 1px auto; max-width: 650px" v-if="isAdmin">
    <InputText
      v-model="searchTextData"
      placeholder="Suchwert, z.B. Name eines Spielers, einer Mannschaft oder Liga"
      @input="executeSearch"
      style="min-width: 100%"
    />
  </div>

  <div
    style="display: block; padding: 5px 10px; margin: 1px auto; max-width: 650px"
    v-show="searchResultContainers.length >= 1"
  >
    <div>
      <Accordion :activeIndex="activeIndex" style="padding-bottom: 20px">
        <AccordionTab
          v-for="searchResultContainer in searchResultContainers"
          v-bind:key="searchResultContainer.name"
          :header="
            searchResultContainer.name +
            '    (' +
            searchResultContainer.counterString +
            ')'
          "
        >
          <div
            style="text-align: left; margin: 5px 30px"
            v-for="searchResult in searchResultContainer.results"
            v-bind:key="searchResult.description"
          >
            <router-link
              :to="
                '/recordview/' + searchResultContainer.recordType + '/' + searchResult.id
              "
              >{{ searchResult.description }}</router-link
            >
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
  <div style="display: block; padding: 5px 10px; margin: 10px auto; max-width: 1000px">
    <AdminLatestGamesView />
  </div>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import AdminLatestGamesView from "./AdminLatestGamesView";
import InputText from "primevue/inputtext";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
var searchTextExternal = "";
export default {
  name: "AdminDashBoard",
  components: {
    InputText,
    Accordion,
    AccordionTab,
    AdminLatestGamesView,
  },
  computed: {
    isAdmin() {
      var user = JSON.parse(localStorage.getItem("user"));
      return user && user.roles && user.roles.includes("admin");
    },
  },
  data() {
    return {
      searchTextData: this.searchText ? this.searchText : searchTextExternal,
      searchTextQueue: [],
      searchResultContainers: [],
      activeIndex: -1,
    };
  },
  props: {
    searchText: {
      type: String,
      required: false,
    },
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    }
    this.searchTextData = "";
    this.updateMainMenu();
    if (this.searchTextData && this.searchTextData.length > 1) {
      this.executeSearch();
    }
  },
  updated() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    }
    this.updateMainMenu();
  },
  methods: {
    updateMainMenu() {
      const menuService = useMenuService();
      menuService.setMainMenuItemNames(["settings", "sidebar", "logout"]);
      menuService.setSidebarItems([
        {
          label: "Neue Mannschaft",
          icon: "pi pi-plus",
          command: () => {
            this.$router.push("/newrecord/team/");
          },
        },
        {
          label: "Neuer Spielort",
          icon: "pi pi-plus",
          command: () => {
            this.$router.push("/newrecord/location/");
          },
        },
        {
          label: "Spielplanverwaltung",
          icon: "pi pi-calendar",
          command: () => {
            this.$router.push("/schedule");
          },
        },
      ]);
    },
    executeSearch() {
      this.activeIndex = null;
      this.searchResultContainers = [];
      if (this.searchTextData && this.searchTextData != "") {
        searchTextExternal = this.searchTextData;
        ServerConnection.get("search?searchText=" + this.searchTextData)
          .then((response) => response.data)
          .then((dataList) => {
            this.addSearchResult(dataList);
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    addSearchResult(dataList) {
      this.searchResultContainers = [];
      dataList.forEach((data) => {
        if (data.searchItems.length > 0) {
          this.searchResultContainers.push({
            name: data.recordDisplayName,
            recordType: data.recordType,
            results: data.searchItems,
            counterString:
              data.searchItems.length + (data.seachResultExeceedsMaxCount ? "+" : ""),
          });
        }
      });
      if (
        this.searchResultContainers.length > 0 &&
        this.searchResultContainers.length < 2
      ) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = undefined;
      }
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style>
.p-overlay-badge .p-badge {
  transform: translate(0%, -25%);
}
</style>
