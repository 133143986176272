<template>
  <div style="margin-top: 10px">
    <h3>{{ title }}</h3>
    <Steps
      :model="pageModel.pages"
      :readonly="false"
      style="width: 100%; margin-bottom: 10px"
    />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          v-model:wizardRecordModel="wizardRecordModel"
          ref="pageComponent"
        />
      </keep-alive>
    </router-view>
    <div
      v-if="showNextButton()"
      class="grid grid-nogutter"
      style="display: flex; justify-content: center"
    >
      <i style="margin: 10px 100px"></i>
      <Button
        label="Weiter"
        @click="nextPage()"
        icon="pi pi-angle-right"
        icon-pos="right"
        style="margin: 10px 50px"
      ></Button>
    </div>
    <div
      v-else-if="showCompleteButton()"
      class="grid grid-nogutter"
      style="display: flex; justify-content: center"
    >
      <Button
        label="Zurück"
        v-if="showPreviousButton()"
        @click="prevPage()"
        icon="pi pi-angle-left"
        style="margin: 10px 50px"
      ></Button>
      <Button
        label="Abschließen"
        @click="complete()"
        icon="pi pi-check"
        icon-pos="right"
        class="p-button-success"
        style="margin: 10px 50px"
      ></Button>
    </div>
    <div
      v-else-if="showPreviousButton()"
      class="grid grid-nogutter"
      style="display: flex; justify-content: center"
    >
      <Button
        label="Back"
        @click="prevPage()"
        icon="pi pi-angle-left"
        style="margin: 10px 50px"
      ></Button>
      <Button
        label="Next"
        @click="nextPage()"
        icon="pi pi-angle-right"
        icon-pos="right"
        style="margin: 10px 50px"
      ></Button>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
const pageModels = [
  {
    wizardId: "changeTeam",
    titleFunction: (recordModel) =>
      "Mannschaftswechsel von " + recordModel.baseRecord.representation,
    pages: [
      { label: "Neue Mannschaft auswählen", to: "/wizard/change_team_page1" },
      { label: "Wechsel abschließen", to: "/wizard/change_team_page2" },
    ],
    submitFunction: (recordModel, callBack) => {
      if (recordModel.newVerein) {
        recordModel.baseRecord.association = recordModel.newVerein;
      }
      if (recordModel.newMannschaft) {
        recordModel.baseRecord.team = recordModel.newMannschaft;
        recordModel.baseRecord.active = true;
      }
      ServerConnection.put(
        "data/player/" + recordModel.baseRecord.id,
        JSON.stringify(recordModel.baseRecord)
      )
        .then(callBack)
        .catch((error) => {
          console.error("Error saving the record", error);
        });
    },
  },
  {
    wizardId: "addPlayer",
    titleFunction: (recordModel) =>
      "Spieler aus dem Verein zur Mannschaft " +
      recordModel.baseRecord.representation +
      " hinzufügen",
    pages: [{ label: "Spieler auswählen", to: "/wizard/add_player_page1" }],
    submitFunction: (recordModel, callBack) => {
      if (recordModel.selectedPlayers && recordModel.selectedPlayers.length > 0) {
        for (var selectedPlayer of recordModel.selectedPlayers) {
          selectedPlayer.team = recordModel.baseRecord;
          ServerConnection.put(
            "data/player/" + selectedPlayer.id,
            JSON.stringify(selectedPlayer)
          )
            .then(callBack)
            .catch((error) => {
              console.error("Error saving the record", error);
            });
        }
      } else {
        Promise.resolve().then(callBack);
      }
    },
  },
  {
    wizardId: "changeLocation",
    titleFunction: (recordModel) =>
      "Spielort der Mannschaft (Bisher:" +
      recordModel.baseRecord.location.representation +
      ") ändern",
    pages: [{ label: "Neuer Spielort auswählen", to: "/wizard/change_location" }],
    submitFunction: (recordModel, callBack) => {
      recordModel.baseRecord.location = recordModel.newLocation;
      ServerConnection.put(
        "data/team/" + recordModel.baseRecord.id,
        JSON.stringify(recordModel.baseRecord)
      )
        .then(callBack)
        .catch((error) => {
          console.error("Error saving the record", error);
        });
    },
  },
  {
    wizardId: "setWinner",
    titleFunction: () => "Sieger des Spiels setzen",
    pages: [
      { label: "Eine der Mannschaften zum Sieger erklären", to: "/wizard/set_winner" },
    ],
    submitFunction: (recordModel, callBack) => {
      if (recordModel.winner == "H") {
        recordModel.baseRecord.pointsHome = 12;
        recordModel.baseRecord.pointsGuest = 0;
        recordModel.baseRecord.gameSets = [];
        recordModel.baseRecord.gameStatus = "Played";
      } else if (recordModel.winner == "G") {
        recordModel.baseRecord.pointsHome = 0;
        recordModel.baseRecord.pointsGuest = 12;
        recordModel.baseRecord.gameSets = [];
        recordModel.baseRecord.gameStatus = "Played";
      }
      ServerConnection.put(
        "data/game/" + recordModel.baseRecord.id,
        JSON.stringify(recordModel.baseRecord)
      )
        .then(callBack)
        .catch((error) => {
          console.error("Error saving the record", error);
        });
    },
  },
  {
    wizardId: "switchHomeGuest",
    titleFunction: () => "Heimrecht tauschen/wechseln",
    pages: [
      {
        label:
          "Das Heimrecht mit dem Rückspiel tauschen oder das Heimrecht für dieses Spiel wechseln",
        to: "/wizard/switchHomeGuest",
      },
    ],
    submitFunction: (recordModel, callBack) => {
      ServerConnection.put(
        "data/game/" +
          recordModel.baseRecord.id +
          "/switchHomeAndGuest?switchRematch=" +
          recordModel.switchRematch
      )
        .then(callBack)
        .catch((error) => {
          console.error("Error saving the record", error);
        });
    },
  },
  {
    wizardId: "switchHomeGuestRequest",
    titleFunction: () => "Anfrage zum Ändern des Heimrecht für das Spiel",
    pages: [
      {
        label:
          "Die gegnerische Mannschaft anfragen, ob das Heimrecht mit dem Rückspiel getauscht oder das Heimrecht für dieses Spiel gewechselt werden kann",
        to: "/wizard/switchHomeGuestRequest",
      },
    ],
    submitFunction: (recordModel, callBack) => {
      ServerConnection.put(
        "teamadmin/request/switchHomeGuest?teamId=" +
          recordModel.teamId +
          "&gameId=" +
          recordModel.baseRecord.id +
          "&switchRematch=" +
          recordModel.switchRematch,
        recordModel.notes
      )
        .then(callBack)
        .catch((error) => {
          console.error("Error saving the record", error);
        });
    },
  },
  {
    wizardId: "changeDateRequest",
    titleFunction: () => "Anfrage zum Ändern des Austragungsdatums für das Spiel",
    pages: [
      {
        label:
          "Die gegnerische Mannschaft anfragen, ob das Spiel an einem anderen Datum ausgetragen werden kann.",
        to: "/wizard/changeDateRequest",
      },
    ],
    submitFunction: (recordModel, callBack) => {
      ServerConnection.put(
        "teamadmin/request/changeDate?teamId=" +
          recordModel.teamId +
          "&gameId=" +
          recordModel.baseRecord.id,
        JSON.stringify({
          notes: recordModel.notes,
          dates: recordModel.selectedDates.map((d) => {
            var day = d.value.getDate();
            var month = d.value.getMonth() + 1;
            var hour = d.value.getHours();
            var minutes = d.value.getMinutes();
            return (
              d.value.getFullYear() +
              "-" +
              (month < 10 ? "0" + month : month) +
              "-" +
              (day < 10 ? "0" + day : day) +
              " " +
              (hour < 10 ? "0" + hour : hour) +
              ":" +
              (minutes < 10 ? "0" + minutes : minutes)
            );
          }),
        })
      )
        .then(callBack)
        .catch((error) => {
          console.error("Error saving the record", error);
        });
    },
  },
];

import Steps from "primevue/steps";
import Button from "primevue/button";
import { useMenuService } from "../../services/MenuService/UseMenuService";
import { useWizardService } from "../../services/WizardService/UseWizardService";

export default {
  components: {
    Steps,
    Button,
  },
  data() {
    return {
      page: 0,
      pageModel: {},
      wizardRecordModel: {},
      wizardService: useWizardService(),
      menuService: useMenuService(),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    title() {
      return this.pageModel.titleFunction(this.wizardRecordModel);
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.updateMenu();
    this.updateWizardModels();
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.updateMenu();
    this.updateWizardModels();
  },
  methods: {
    updateMenu() {
      this.menuService.setSidebarVisisble(false);
      this.menuService.setMainMenuItemNames([
        "home",
        {
          name: "cancel",
          command: () => {
            this.wizardService.navigateToPreviousSite(this.$router);
          },
        },
      ]);
    },
    findWizardMetaModel(wizardId) {
      const models = pageModels.filter((model) => model.wizardId == wizardId);
      if (models.length < 1) {
        throw new Error(`Could not find wizard meta model for ${wizardId}`);
      }
      if (models.length > 1) {
        throw new Error(`Ambiguous wizard meta models for ${wizardId}`);
      }
      return models[0];
    },
    updateWizardModels() {
      this.wizardRecordModel = this.wizardService.getWizardRecordModel();
      this.pageModel = this.findWizardMetaModel(this.wizardService.getWizardId());

      var nextRoute = this.pageModel.pages[this.page].to;
      if (!this.$route.path.endsWith(nextRoute)) {
        this.$router.push(nextRoute);
      }
    },
    showNextButton() {
      if (this.pageModel) {
        return this.page < this.pageModel.pages.length - 1;
      }
      return false;
    },
    showPreviousButton() {
      if (this.pageModel) {
        return this.page > 0;
      }
      return false;
    },
    showCompleteButton() {
      if (this.pageModel) {
        return this.page == this.pageModel.pages.length - 1;
      }
      return false;
    },
    nextPage() {
      if (this.$refs.pageComponent.validate()) {
        this.$refs.pageComponent.updateModel();
        this.page++;
        this.$router.push(this.pageModel.pages[this.page].to);
      }
    },
    prevPage() {
      this.$refs.pageComponent.updateModel();
      this.page--;
      this.$router.push(this.pageModel.pages[this.page].to);
    },
    complete() {
      if (this.$refs.pageComponent.validate()) {
        this.pageModel.submitFunction(this.wizardRecordModel, () =>
          this.wizardService.navigateToPreviousSite(this.$router)
        );
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
