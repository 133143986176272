<template>
  <div class="flex flex-column">
    <div
      v-if="withHeader"
      class="align-items-center justify-content-center"
      style="font-size: 20px; text-align: center; width: 100%"
    >
      {{ conversation.subject }}
    </div>
    <div class="flex flex-column align-items-center justify-content-center">
      <h3 style="margin: 10px">Anfrage</h3>
      <div v-html="conversation.headerMessage"></div>
    </div>
    <div
      v-if="conversation.resultingAnswer && conversation.resultingAnswer.length > 0"
      style="margin: 10px 5px"
      class="flex flex-column align-items-center justify-content-center flex-wrap"
    >
      <h3 style="margin: 10px">Antwort</h3>
      <div v-html="conversation.resultingAnswer" />
    </div>
    <div
      style="margin: 10px 5px"
      class="flex justify-content-center"
      v-else-if="answerRequested"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <h3>Mögliche Antworten</h3>
        <div class="flex flex-column align-items-center justify-content-center">
          <div
            style="padding: 5px"
            v-bind:key="possibleAnswer.label"
            v-for="possibleAnswer in possibleAnswers"
          >
            <Button
              :label="possibleAnswer.label"
              @click="answerButtonClicked(possibleAnswer)"
            />
          </div>
        </div>
        <Button
          label="Abbrechen"
          @click="cancelButtonClicked()"
          style="margin-top: 15px"
        />
      </div>
    </div>
    <div
      class="flex flex-column align-items-center justify-content-center"
      style="max-width: 1000px; width: 100%"
    >
      <h3 style="margin: 10px">Nachrichtenverlauf</h3>
      <DataTable
        class="p-datatable-sm"
        :value="conversation.messages"
        stripedRows
        responsiveLayout="stack"
        breakpoint="550px"
        style="margin-top: 10px; width: 100%"
      >
        <Column header="Erstellt am">
          <template #body="slotProps">
            {{ formatDateTimeInfo(slotProps.data.timestamp) }}
          </template>
        </Column>
        <Column field="author" header="Ersteller"></Column>
        <Column header="Nachricht">
          <template #body="slotProps">
            <div v-html="slotProps.data.message"></div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <Dialog
    id="reasonDialog"
    modal
    v-model:visible="displayReasonDialog"
    style="min-width: 300px; max-width: 600px"
    :closable="false"
  >
    <template #header>
      <div
        class="align-items-center justify-content-center"
        style="font-size: 18px; text-align: center; width: 100%"
      >
        Bitte eine kurze Begründung oder Alternativen für die Antwort:
        {{ choosenAnswer.label }}
      </div>
    </template>
    <div class="flex flex-column align-items-center justify-content-center">
      <Textarea
        v-model="answerReason"
        :autoResize="false"
        style="width: 100%; height: 30vh; margin-top: 5px"
      />
    </div>
    <template #footer>
      <div>
        <Button label="Antwort abschließen" @click="closeReasonDialog(true)" />
        <Button label="Abbrechen" @click="closeReasonDialog(false)" />
      </div>
    </template>
  </Dialog>
  <Dialog
    id="ackDialog"
    modal
    v-model:visible="displayAckDialog"
    style="min-width: 300px; max-width: 600px"
    :closable="false"
  >
    <template #header>
      <div
        class="align-items-center justify-content-center"
        style="font-size: 18px; text-align: center; width: 100%"
      >
        {{ conversation.subject }}
      </div>
    </template>
    <div class="flex flex-column align-items-center justify-content-center">
      Soll die Antwort: {{ choosenAnswer.label }} gesendet werden?
    </div>
    <template #footer>
      <div>
        <Button label="Antwort senden" @click="closeAckDialog(true)" />
        <Button label="Abbrechen" @click="closeAckDialog(false)" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "Conversation",
  components: {
    Button,
    Textarea,
    Dialog,
    DataTable,
    Column,
  },
  emits: {
    answered: "",
  },
  props: {
    conversation: {},
    withHeader: Boolean,
  },
  created() {},
  data() {
    return {
      displayReasonDialog: false,
      displayAckDialog: false,
      choosenAnswer: {},
      answerReason: "",
    };
  },
  computed: {
    possibleAnswers() {
      if (
        this.conversation &&
        this.conversation.possibleAnswers &&
        this.conversation.possibleAnswers.length > 0
      ) {
        return JSON.parse(this.conversation.possibleAnswers);
      }
      return "[]";
    },
    answerRequested() {
      return this.conversation && this.conversation.answerRequested;
    },
  },
  methods: {
    answerButtonClicked(answer) {
      this.choosenAnswer = answer;
      if (answer && answer.reason) {
        this.displayReasonDialog = true;
      } else {
        this.displayAckDialog = true;
      }
    },
    cancelButtonClicked() {
      this.$emit("answered", false);
    },
    closeAckDialog(finish) {
      this.displayAckDialog = false;
      if (finish) {
        this.saveAnswer();
      }
    },
    closeReasonDialog(finish) {
      this.displayReasonDialog = false;
      if (finish) {
        this.saveAnswer();
      }
      this.answerReason = "";
    },
    saveAnswer() {
      var answerObject = {};
      answerObject.answer = this.choosenAnswer.label;
      answerObject.type = this.choosenAnswer.type;
      answerObject.reason = this.answerReason;
      answerObject.conversationMessageType = "ANSWER";
      ServerConnection.post(
        "conversation/" + this.conversation.id + "/answer",
        JSON.stringify(answerObject)
      )
        .then(() => this.$emit("answered", true))
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    formatDateTimeInfo(date) {
      if (date && date.length > 0) {
        var pattern = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/;
        return date.replace(pattern, "$3.$2.$1 $4:$5 Uhr");
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
