<template>
  <div>
    <div v-if="editMode">
      <div>
        <div>
          <FileUpload
            ref="fileUpload"
            mode="basic"
            :customUpload="true"
            :previewWidth="220"
            :chooseLabel="uploadLabel"
            accept="image/*"
            @uploader="upload"
            :auto="true"
          />
        </div>
      </div>

      <div v-if="uploadInProgress" style="margin: 10px">
        <ProgressBar :value="progress" />
      </div>

      <div v-if="message" style="margin: 10px">
        {{ message }}
      </div>
    </div>
    <div v-if="image" style="margin: 5px 0px">
      <div v-if="editMode">
        <Button
          @click="rotateImage(-90)"
          v-tooltip.bottom="'Bild gegen den Uhrzeigersinn drehen'"
          icon="pi pi-replay"
          style="margin: 10px 20px"
        />
        <Button
          @click="rotateImage(90)"
          v-tooltip.bottom="'Bild im Uhrzeigersinn drehen'"
          icon="pi pi-refresh"
          style="margin: 10px 20px"
        />
      </div>
      <Image :src="image" imageStyle="width: 100%; minWidth: 300px" preview />
    </div>
  </div>
</template>

<script>
import UploadImageService from "../../../services/UploadImageService/UploadImageService";
import ProgressBar from "primevue/progressbar";
import Image from "primevue/image";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
export default {
  name: "UploadGameResult",
  components: {
    ProgressBar,
    Image,
    FileUpload,
    Button,
  },
  computed: {
    uploadLabel() {
      return this.image
        ? "Anderes Bild auswählen und hochladen"
        : "Bild auswählen und hochladen";
    },
  },
  mounted() {
    UploadImageService.getGameResultImage(this.gameId).then((response) => {
      if (response.data) {
        this.image =
          "data:" + response.headers["content-type"] + ";base64," + response.data;
        this.$emit("imageChanged");
      }
    });
  },
  data() {
    return {
      uploadInProgress: false,
      progress: 0,
      message: "",
      image: undefined,
    };
  },
  props: {
    gameId: Number,
    editMode: Boolean,
  },
  methods: {
    upload(event) {
      this.progress = 0;
      this.uploadInProgress = true;
      UploadImageService.uploadGameResultImage(this.gameId, event.files[0], (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then((response) => {
          this.message = response.data.message;
          this.$refs.fileUpload.clear();
          this.$refs.fileUpload.uploadedFileCount = 0;
          this.uploadInProgress = false;
          UploadImageService.getGameResultImage(this.gameId).then((response) => {
            if (response.data) {
              this.image =
                "data:" + response.headers["content-type"] + ";base64," + response.data;
              this.$emit("imageChanged");
            }
          });
        })
        .catch((err) => {
          this.progress = 0;
          this.message = "Could not upload the image! " + err;
          this.uploadInProgress = false;
        });
    },
    imageExists() {
      return this.image && this.image.length > 0;
    },
    rotateImage(rotation) {
      UploadImageService.rotateGameResultImage(this.gameId, rotation).then(() => {
        UploadImageService.getGameResultImage(this.gameId).then((response) => {
          if (response.data) {
            this.image =
              "data:" + response.headers["content-type"] + ";base64," + response.data;
            this.$emit("imageChanged");
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
