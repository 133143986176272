export const gameDaysFieldDescriptions = [
    {
        name: "numberOfGameDay",
        label: "Spieltage",
        fieldType: "Integer",
      },
      {
        name: "gameDayStart",
        label: "Startdatum",
        fieldType: "Date",
      },
      {
        name: "gameDayEnd",
        label: "EndDatum",
        fieldType: "Date",
      }
];
export const gameDayLinkDescriptions = [

];