<template>
  <admin-dash-board v-if="isAdmin" />
  <team-admin-dash-board v-else-if="isTeamAdmin" />
  <div v-else>
    <h3>Geschlossener Bereich</h3>
    <h4>Login erforderlich</h4>
  </div>
</template>

<script>
import AdminDashBoard from "./admin/AdminDashBoard.vue";
import TeamAdminDashBoard from "./teamAdmin/TeamAdminDashBoard.vue";
export default {
  name: "DashBoard",
  components: {
    AdminDashBoard,
    TeamAdminDashBoard,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin() {
      if (!this.loggedIn) {
        return false;
      }
      var user = JSON.parse(localStorage.getItem("user"));
      return user && user.roles && user.roles.includes("admin");
    },
    isTeamAdmin() {
      if (!this.loggedIn) {
        return false;
      }
      var user = JSON.parse(localStorage.getItem("user"));
      return (
        user &&
        user.roles &&
        !user.roles.includes("admin") &&
        user.roles.includes("teamAdmin")
      );
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
  data() {
    return {};
  },
};
</script>

<style>
.p-overlay-badge .p-badge {
  transform: translate(0%, -25%);
}
</style>
