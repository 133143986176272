<template>
  <div class="flex align-items-center justify-content-center">
    <div style="width: 100%; max-width: 1600px">
      <Dropdown
        v-model="selectedPreSeason"
        :options="preSeasons"
        optionLabel="name"
        placeholder="Eine Saison wählen"
        style="margin: 10px 2px"
        @change="selectedPreSeasonChanged"
      />
      <Stepper>
        <StepperPanel header="Ligagruppen">
          <template #content>
            <div class="flex flex-column h-12rem">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else class="flex overflow-hidden" style="width: 100%">
                  <Accordion :activeIndex="0" style="width: 100%">
                    <AccordionTab
                      v-for="preSeasonLeagueTemplate in selectedPreSeason.preSeasonLeagueTemplates"
                      :key="preSeasonLeagueTemplate.prefix"
                      :header="preSeasonLeagueTemplate.prefix"
                    >
                      <label for="numberOfLeagues">Anzahl Ligen:</label>
                      <InputNumber
                        style="margin: 10px"
                        inputClass="input-class"
                        id="numberOfLeagues"
                        v-model="preSeasonLeagueTemplate.numberOfLeagues"
                        inputId="integeronly"
                        @input="numberOfLeaguesChanged(preSeasonLeagueTemplate)"
                        showButtons
                        buttonLayout="horizontal"
                        ><template #incrementbuttonicon>
                          <span class="pi pi-plus" />
                        </template>
                        <template #decrementbuttonicon>
                          <span class="pi pi-minus" />
                        </template>
                      </InputNumber>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="Mannschaften zu Ligagruppen" style="width: 100%">
          <template #content>
            <div class="flex flex-column h-12rem" style="width: 100%">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                style="width: 100%"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else style="width: 100%">
                  <div
                    class="flex-none flex align-items-center justify-content-center"
                    style="width: 100%; height: 100%"
                  >
                    <Dropdown
                      v-model="selectedPreSeasonLeagueTemplate"
                      :options="selectedPreSeason.preSeasonLeagueTemplates"
                      optionLabel="prefix"
                      placeholder="Eine Ligagruppe wählen"
                      @change="selectedLeagueTemplateChanged"
                      style="width: 280px"
                    ></Dropdown>
                  </div>
                  <div
                    class="flex-grow-1 flex align-items-center justify-content-center"
                    style="width: 100%; height: 100%"
                  >
                    <PickList
                      v-model="teamsPickList"
                      dataKey="id"
                      :showSourceControls="false"
                      :showTargetControls="false"
                      @update:modelValue="teamInLeagueTemplateChanged"
                      style="width: 100%"
                      ><template #sourceheader> Verfügbar </template>
                      <template #targetheader> In der Liga </template>
                      <template #item="slotProps">
                        <span class="font-bold">{{ slotProps.item.name }}</span>
                      </template></PickList
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="Ligen">
          <template #content>
            <div class="flex flex-column h-12rem">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else class="flex overflow-hidden" style="width: 100%">
                  <Accordion :activeIndex="0" style="width: 100%">
                    <AccordionTab
                      v-for="preSeasonLeagueTemplate in selectedPreSeason.preSeasonLeagueTemplates"
                      :key="preSeasonLeagueTemplate.prefix"
                      :header="preSeasonLeagueTemplate.prefix"
                    >
                      <Button
                        v-if="
                          !selectedPreSeason.preSeasonLeagues ||
                          currentExistingPreSeasonLeagues(preSeasonLeagueTemplate.prefix)
                            .length < preSeasonLeagueTemplate.numberOfLeagues
                        "
                        :label="preSeasonLeagueTemplate.prefix + ' hinzufügen'"
                        icon="pi pi-plus"
                        @click="addPreSeasonLeague(preSeasonLeagueTemplate.prefix)"
                      />
                      <DataTable
                        class="p-datatable-sm"
                        :value="
                          currentExistingPreSeasonLeagues(preSeasonLeagueTemplate.prefix)
                        "
                        editMode="cell"
                        @cell-edit-complete="savePreSeasonLeague"
                        stripedRows
                        responsiveLayout="stack"
                        breakpoint="550px"
                        style="margin-top: 15px; width: 100%"
                      >
                        <Column field="name" header="Name">
                          <template #body="{ data, field }">
                            {{ data[field] }}
                          </template>
                          <template #editor="{ data, field }">
                            <template v-if="field !== 'price'">
                              <InputText v-model="data[field]" autofocus />
                            </template>
                          </template>
                        </Column>
                        <Column field="weekType" header="Woche">
                          <template #body="{ data, field }">
                            {{ data[field] == "EVEN" ? "Gerade" : "Ungerade" }}
                          </template>
                          <template #editor="{ data, field }">
                            <template v-if="field !== 'price'">
                              <Dropdown
                                v-model="data[field]"
                                :options="[
                                  { label: 'Gerade', value: 'EVEN' },
                                  { label: 'Ungerade', value: 'ODD' },
                                ]"
                                optionLabel="label"
                                style="width: 200px"
                              ></Dropdown>
                            </template>
                          </template>
                        </Column>
                        <Column header="Aktionen" style="width: 100px">
                          <template #body="slotProps">
                            <Button
                              style="margin: 2px 2px"
                              icon="pi pi-trash"
                              v-tooltip.top="'Löschen'"
                              class="p-button-rounded p-button-warning"
                              @click="deletePreSeasonLeague(slotProps.data)"
                            />
                          </template>
                        </Column>
                      </DataTable>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>

        <StepperPanel header="Bedingungen / Wünsche">
          <template #content>
            <div class="flex flex-column h-12rem">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else class="flex overflow-hidden" style="width: 100%">
                  <div
                    class="flex-none flex align-items-center justify-content-center bg-primary font-bold border-round"
                  >
                    <Listbox
                      v-model="selectedTeam"
                      :options="allTeams"
                      optionLabel="name"
                      @change="selectedTeamChanged"
                    ></Listbox>
                  </div>
                  <div
                    class="flex-grow-1 flex align-items-start justify-content-center border-round"
                  >
                    <div
                      v-if="selectedTeam && selectedTeam.id"
                      class="flex flex-column"
                      style="width: 100%"
                    >
                      <div
                        class="flex align-items-center justify-content-center border-round m-2"
                      >
                        <Button
                          label="Bedingung oder Wunsch hinzufügen"
                          icon="pi pi-plus"
                          @click="addCriteria"
                        />
                      </div>
                      <div class="flex align-items-center justify-content-center">
                        <DataTable
                          class="p-datatable-sm"
                          :value="teamCriterias"
                          stripedRows
                          responsiveLayout="stack"
                          breakpoint="550px"
                          style="margin-top: 15px; width: 100%"
                        >
                          <Column field="type" header="Art">
                            <template #body="{ data }">
                              <span>{{ getLabelForCriteriaType(data) }}</span>
                            </template>
                          </Column>
                          <Column field="priority" header="Priorität"></Column>
                          <Column header="Aktionen" style="width: 100px">
                            <template #body="slotProps">
                              <Button
                                style="margin: 2px 2px"
                                icon="pi pi-pencil"
                                v-tooltip.top="'Editieren'"
                                class="p-button-rounded p-button-success mb2"
                                @click="editCriteria(slotProps.data)"
                              />
                              <Button
                                style="margin: 2px 2px"
                                icon="pi pi-trash"
                                v-tooltip.top="'Löschen'"
                                class="p-button-rounded p-button-warning"
                                @click="deleteCriteria(slotProps.data)"
                              />
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </div>
                    <div v-else>
                      <h3>Keine Mannschaft ausgewählt</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="Mannschaften zu Ligen" style="width: 100%">
          <template #content>
            <div class="flex flex-column h-12rem" style="width: 100%">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                style="width: 100%"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else style="width: 100%">
                  <div
                    class="flex-none flex align-items-center justify-content-center"
                    style="width: 100%; height: 100%"
                  >
                    <Dropdown
                      v-model="selectedPreSeasonLeague"
                      :options="selectedPreSeason.preSeasonLeagues"
                      optionLabel="name"
                      placeholder="Eine Liga wählen"
                      @change="selectedLeagueChanged"
                    ></Dropdown>
                  </div>
                  <div
                    class="flex-grow-1 flex align-items-center justify-content-center"
                    style="width: 100%; height: 100%"
                  >
                    <PickList
                      v-model="teamsPickList"
                      dataKey="id"
                      :showSourceControls="false"
                      :showTargetControls="false"
                      @update:modelValue="teamInLeagueChanged"
                      style="width: 100%"
                      ><template #sourceheader> Verfügbar </template>
                      <template #targetheader> In der Liga </template>
                      <template #item="slotProps">
                        <span class="font-bold">{{ slotProps.item.name }}</span>
                      </template></PickList
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="Spieltage">
          <template #content>
            <div class="flex flex-column h-12rem">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else class="flex flex-column" style="width: 100%">
                  <div
                    class="flex align-items-center justify-content-center"
                    style="width: 100%"
                  >
                    <div
                      class="flex justify-content-center flex-wrap"
                      style="width: 100%"
                    >
                      <div
                        class="flex align-items-center justify-content-center"
                        style="width: 100%"
                      >
                        <label for="firstScheduleStart"
                          >Beginn der Saison für die 1. Liga-Gruppe:</label
                        >
                        <Calendar
                          style="margin: 10px"
                          v-model="firstScheduleStart"
                          inputId="firstScheduleStart"
                          dateFormat="dd.mm.yy"
                          @update:modelValue="updateScheduleStart(true)"
                        />
                      </div>
                      <div
                        class="flex align-items-center justify-content-center"
                        style="width: 100%"
                      >
                        <label for="secondScheduleStart"
                          >Beginn der Saison für die 2. Liga-Gruppe:</label
                        >
                        <Calendar
                          style="margin: 10px"
                          v-model="secondScheduleStart"
                          inputId="secondScheduleStart"
                          dateFormat="dd.mm.yy"
                          @update:modelValue="updateScheduleStart(true)"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="gameDays && gameDays.length > 0"
                    class="flex align-items-center justify-content-center"
                  >
                    <DataTable
                      class="p-datatable-sm"
                      :value="gameDays"
                      :rowStyle="gameDayRowStyle"
                      stripedRows
                      responsiveLayout="stack"
                      breakpoint="550px"
                      style="margin-top: 15px; width: 100%"
                    >
                      <Column field="number" header="Spieltag" />
                      <Column field="dateString" header="Datum" />
                      <Column field="moveCause" header="Verschoben wegen" />
                      <Column header="Aktionen" style="width: 100px">
                        <template #body="slotProps">
                          <Button
                            style="margin: 2px 2px"
                            icon="pi pi-calendar"
                            v-tooltip.top="'Verschieben'"
                            class="p-button-rounded p-button-success mb2"
                            @click="changeGameDay(slotProps.data)"
                          />
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                  <div v-else>
                    <h4>Beginn der beiden Gruppen bestimmen</h4>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>
        <StepperPanel header="Spielplan">
          <template #content>
            <div class="flex flex-column h-12rem">
              <div
                class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
              >
                <div v-if="!selectedPreSeason || !selectedPreSeason.name">
                  <h3>Keine Saison ausgewählt</h3>
                </div>
                <div v-else class="flex flex-column" style="width: 100%">
                  <div
                    class="flex align-items-center justify-content-center"
                    style="width: 100%"
                  >
                    <Button
                      v-if="
                        scheduleCalculationStatus &&
                        scheduleCalculationStatus.status == 'RUNNING'
                      "
                      style="margin: 2px 2px"
                      icon="pi pi-pause"
                      label="Berechnung des Spielplans stoppen"
                      class="p-button-rounded p-button-success mb2"
                      @click="changeScheduleCalculationStatus('pause')"
                    />
                    <Button
                      v-else-if="
                        scheduleCalculationStatus &&
                        scheduleCalculationStatus.status == 'PAUSED'
                      "
                      style="margin: 2px 2px"
                      icon="pi pi-play"
                      label="Berechnung des Spielplans fortsetzen"
                      class="p-button-rounded p-button-success mb2"
                      @click="changeScheduleCalculationStatus('start')"
                    />
                    <Button
                      v-else-if="
                        scheduleCalculationStatus &&
                        scheduleCalculationStatus.status == 'FINISHED'
                      "
                      style="margin: 2px 2px"
                      icon="pi pi-play"
                      label="Berechnung des Spielplans neu starten"
                      class="p-button-rounded p-button-success mb2"
                      @click="changeScheduleCalculationStatus('start')"
                    />
                    <Button
                      v-else
                      style="margin: 2px 2px"
                      icon="pi pi-play"
                      label="Berechnung des Spielplans starten"
                      class="p-button-rounded p-button-success mb2"
                      @click="changeScheduleCalculationStatus('start')"
                    />
                  </div>
                  <div
                    v-if="
                      scheduleCalculationStatus &&
                      (scheduleCalculationStatus.lowestValidationPoints >= 0 ||
                        scheduleCalculationStatus.invalidSchedulesCount > 0)
                    "
                    class="flex align-items-center justify-content-center"
                    style="width: 100%"
                  >
                    <div class="flex flex-wrap">
                      <div
                        class="flex align-items-center justify-content-center m-2 p-2 bg-primary border-round"
                      >
                        <span
                          >Gültige Spielpläne:
                          {{ scheduleCalculationStatus.validSchedulesCount }}</span
                        >
                      </div>
                      <div
                        class="flex align-items-center justify-content-center m-2 p-2 bg-primary border-round"
                      >
                        <span
                          >Ungültige Spielpläne:
                          {{ scheduleCalculationStatus.invalidSchedulesCount }}</span
                        >
                      </div>
                      <div
                        class="flex align-items-center justify-content-center m-2 p-2 bg-primary border-round"
                      >
                        <span
                          >Beste/Niedrigste Bewertung:
                          {{ scheduleCalculationStatus.lowestValidationPoints }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center"
                    style="width: 100%"
                  >
                    <Dropdown
                      v-if="
                        scheduleCalculationStatus &&
                        scheduleCalculationStatus.scheduleInfos &&
                        scheduleCalculationStatus.scheduleInfos.length > 0
                      "
                      v-model="selectedSchedule"
                      :options="scheduleCalculationStatus.scheduleInfos"
                      placeholder="Einen Spielplan wählen"
                      style="margin: 10px 2px"
                      @change="selectedScheduleChanged"
                    >
                      <template #value="slotProps">
                        <div v-if="slotProps.value.id" class="flex align-items-center">
                          <div>
                            Spielplan {{ slotProps.value.id }} ({{
                              slotProps.value.validationPoints
                            }})
                          </div>
                        </div>
                        <span v-else>
                          {{ slotProps.placeholder }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="flex align-items-center">
                          <div>
                            Spielplan {{ slotProps.option.id }} ({{
                              slotProps.option.validationPoints
                            }})
                          </div>
                        </div>
                      </template></Dropdown
                    >
                  </div>
                  <div
                    class="flex align-items-center justify-content-center"
                    style="width: 100%"
                  >
                    <div v-if="currentSchedule && currentSchedule.length > 0">
                      <DataTable
                        class="p-datatable-sm"
                        :value="currentSchedule"
                        stripedRows
                        sortMode="multiple"
                        responsiveLayout="stack"
                        style="margin-top: 15px; width: 100%"
                      >
                        <Column field="league" header="Liga" sortable />
                        <Column field="gameDayNumber" header="Spieltag" sortable />
                        <Column field="gameDayDate" header="Datum" sortable />
                        <Column field="location" header="Spielort" sortable />
                        <Column field="homeTeam" header="Heimmannschaft" sortable />
                        <Column field="guestTeam" header="Gastmannschaft" sortable />
                        <Column
                          field="distanceForGuestTeam"
                          header="Anfahrt (km)"
                          sortable
                        />
                        <Column
                          field="validationPoints"
                          header="Bewertungszahl"
                          sortable
                        />
                        <Column field="validationResults" header="Bewertungen" sortable />
                      </DataTable>
                    </div>
                    <div v-else-if="selectedSchedule && selectedSchedule.id">
                      <h3>Lade Spielplan</h3>
                      <h4>(das kann mehrere Minuten dauern)</h4>
                      <ProgressSpinner />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StepperPanel>
      </Stepper>
    </div>
  </div>
  <Dialog
    id="criteriaDialog"
    modal
    v-model:visible="displayCriteriaDialog"
    :closable="true"
  >
    <template #header>
      <div
        class="align-items-center justify-content-center"
        style="font-size: 20px; text-align: center; width: 100%"
      >
        Bedingung / Wunsch bearbeiten
      </div>
    </template>
    <div class="flex flex-column">
      <div class="flex align-items-center justify-content-center">
        <Dropdown
          v-model="selectedTeamCriteriaFieldDescription"
          :options="currentTeamPreSeasonTeamCriteriaFieldDescriptions"
          optionLabel="label"
          placeholder="Bedingung / Wunsch auswählen"
          style="width: 350px"
          @change="selectedTeamCriteriaFieldDescriptionChanged()"
        ></Dropdown>
      </div>
      <div
        v-if="
          selectedTeamCriteriaFieldDescription &&
          selectedTeamCriteriaFieldDescription.type
        "
        class="flex flex-column align-items-center justify-content-center"
      >
        <div class="flex flex-column" style="margin: 20px; width: 300px">
          <label>Priorität (je höher desto wichtiger)</label>
          <InputNumber v-model="currentTeamCriteria.priority" :min="1" :max="1000" />
          <Slider v-model="currentTeamCriteria.priority" :min="1" :max="1000" />
        </div>
        <div class="flex flex-column">
          <div
            class="flex align-items-center justify-content-center"
            v-for="recordFieldDescription in selectedTeamCriteriaFieldDescription.fieldDescriptions"
            v-bind:key="recordFieldDescription.name + ':' + Math.random()"
          >
            <RecordField
              v-model:record="currentTeamCriteria"
              :recordFieldDescription="recordFieldDescription"
              :editMode="true"
              :minWidth="300"
            />
          </div>
        </div>
      </div>
      <div v-else></div>
    </div>
    <template #footer>
      <div class="flex justify-content-between flex-wrap card-container">
        <Button label="Abbrechen" @click="closeCriteriaDialog(false)" />
        <Button label="Speichern" @click="closeCriteriaDialog(true)" />
      </div>
    </template>
  </Dialog>
  <Dialog v-model:visible="showDeleteCriteriaDialog" :closable="false">
    <template #header>
      <h3>Bedingung oder Wunsch löschen</h3>
    </template>

    Soll die Bedingung / Wunsch {{ getLabelForCriteriaType(criteriaToDelete.type) }} für
    Mannschaft {{ selectedTeam.name }} wirklich gelöscht werden?

    <template #footer>
      <Button
        label="Löschen"
        icon="pi pi-trash"
        class="p-button-text"
        @click="executeDeleteCriteria"
      />
      <Button label="Abbrechen" icon="pi pi-check" @click="cancelDeleteCriteria" />
    </template>
  </Dialog>
  <Dialog id="gameDayDialog" modal v-model:visible="showGameDayDialog" :closable="true">
    <template #header>
      <div
        class="align-items-center justify-content-center"
        style="font-size: 20px; text-align: center; width: 100%"
      >
        Spieltag verschieben
      </div>
    </template>
    <div class="flex flex-column">
      <div class="flex align-items-center justify-content-center">
        <label>Anderes Datum</label>
      </div>
      <div class="flex">
        <Calendar inline v-model="currentGameDay.dateValue" dateFormat="dd.mm.yy" />
      </div>
      <div class="flex align-items-center justify-content-center">
        <label>Verschoben wegen</label>
      </div>
      <div class="flex align-items-center justify-content-center">
        <InputText v-model="currentGameDay.moveCause" style="width: 100%" />
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-between flex-wrap card-container">
        <Button label="Abbrechen" @click="closeGameDayDialog(false)" />
        <Button label="Speichern" @click="closeGameDayDialog(true)" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ServerConnection from "../../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../../services/ServerConnection/FailureHandler";
import { useMenuService } from "../../../services/MenuService/UseMenuService";
import RecordField from "../../records/RecordField";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import InputNumber from "primevue/inputnumber";
import Stepper from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";
import Dropdown from "primevue/dropdown";
import PickList from "primevue/picklist";
import Listbox from "primevue/listbox";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Slider from "primevue/slider";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
export default {
  name: "Schedule",
  components: {
    Calendar,
    InputNumber,
    Dropdown,
    DataTable,
    Column,
    Dialog,
    Accordion,
    AccordionTab,
    Stepper,
    StepperPanel,
    PickList,
    Listbox,
    Button,
    Slider,
    RecordField,
    InputText,
    ProgressSpinner,
  },
  created() {
    this.updateMenus();
    this.loadSeasons(false);
    this.loadPreSeasonTeamCriteriaFieldDescriptions();
  },
  updated() {
    this.updateMenus();
    this.loadSeasons(false);
    this.loadPreSeasonTeamCriteriaFieldDescriptions();
  },
  data() {
    return {
      selectedPreSeason: {},
      preSeasons: [],
      selectedPreSeasonLeague: {},
      selectedPreSeasonLeagueTemplate: {},
      preSeasonLeagueTemplates: [],
      teamsPickList: [[], []],
      allTeams: [],
      selectedTeam: {},
      allPreSeasonTeamCriteriaFieldDescriptions: [],
      currentTeamPreSeasonTeamCriteriaFieldDescriptions: [],
      selectedTeamCriteriaFieldDescription: {},
      displayCriteriaDialog: false,
      currentTeamCriteria: {},
      teamCriterias: [],
      showDeleteCriteriaDialog: false,
      criteriaToDelete: {},
      firstScheduleStart: null,
      secondScheduleStart: null,
      gameDays: [],
      showGameDayDialog: false,
      currentGameDay: {},
      lastStatusTime: Date.now(),
      scheduleCalculationStatus: {},
      continueLoadingCalculationStatus: false,
      selectedSchedule: {},
      currentSchedule: {},
    };
  },
  props: {},
  methods: {
    loadPreSeasonTeamCriteriaFieldDescriptions() {
      if (
        !this.allPreSeasonTeamCriteriaFieldDescriptions ||
        this.allPreSeasonTeamCriteriaFieldDescriptions.length <= 0
      ) {
        ServerConnection.get("preSeasonTeamCriterias/fieldDescriptions")
          .then((response) => response.data)
          .then((data) => {
            this.allPreSeasonTeamCriteriaFieldDescriptions = data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadUnassignedTeams() {
      if (this.selectedPreSeason && this.selectedPreSeason.id) {
        ServerConnection.get("unassignedTeams?preSeasonId=" + this.selectedPreSeason.id)
          .then((response) => response.data)
          .then((data) => {
            if (!this.teamsPickList) {
              this.teamsPickList = [[], []];
            }
            this.teamsPickList[0] = data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadAllTeams() {
      if (this.selectedPreSeason && this.selectedPreSeason.id) {
        ServerConnection.get("allTeams")
          .then((response) => response.data)
          .then((data) => {
            this.allTeams = data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadSeasons(forceLoading) {
      if (forceLoading || !this.preSeasons || this.preSeasons.length <= 0) {
        ServerConnection.get("preSeasons")
          .then((response) => response.data)
          .then((data) => {
            this.preSeasons = data;
            if (this.selectedPreSeason && this.selectedPreSeason.id) {
              this.selectedPreSeason = this.preSeasons.filter(
                (preSeason) => preSeason.id == this.selectedPreSeason.id
              )[0];
            }
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadTeamCriterias() {
      if (
        this.selectedPreSeason &&
        this.selectedPreSeason.id &&
        this.selectedTeam &&
        this.selectedTeam.id
      ) {
        ServerConnection.get(
          "preSeasonTeamCriterias/" +
            this.selectedPreSeason.id +
            "/" +
            this.selectedTeam.id
        )
          .then((response) => response.data)
          .then((data) => {
            this.teamCriterias = data;
            this.teamCriterias.forEach((tc) => {
              if (tc.parameters && tc.parameters.length > 0) {
                tc.parameters.split(",").forEach((param) => {
                  var split = param.split("=");
                  if (split.length >= 2) {
                    tc[split[0]] = split[1];
                  }
                });
              }
            });
          })
          .then(() => {
            this.currentTeamPreSeasonTeamCriteriaFieldDescriptions = this.allPreSeasonTeamCriteriaFieldDescriptions.filter(
              (tcfd) => {
                var found = this.teamCriterias.find(
                  (tc) => tc.type == tcfd.type && !tcfd.multipleAllowed
                );
                return !found || found.length <= 0;
              }
            );
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    selectedPreSeasonChanged() {
      this.teamsPickList = [""][""];
      this.allTeams = [];
      this.firstScheduleStart = new Date(this.selectedPreSeason.firstScheduleStart);
      this.secondScheduleStart = new Date(this.selectedPreSeason.secondScheduleStart);
      if (this.firstScheduleStart && this.secondScheduleStart) {
        this.updateScheduleStart(false);
      }
      this.loadUnassignedTeams();
      this.loadAllTeams();
    },
    currentExistingPreSeasonLeagues(prefix) {
      if (
        this.selectedPreSeason &&
        this.selectedPreSeason.preSeasonLeagues &&
        this.selectedPreSeason.preSeasonLeagues.length > 0
      ) {
        return this.selectedPreSeason.preSeasonLeagues.filter(
          (psl) => prefix == psl.prefix
        );
      }
      return [];
    },
    addPreSeasonLeague(prefix) {
      if (this.selectedPreSeason) {
        if (!this.selectedPreSeason.preSeasonLeagues) {
          this.selectedPreSeason.preSeasonLeagues = [];
        }
        var newPreSeasonLeague = {
          prefix: prefix,
          name: prefix + " (neu)",
          preSeason: this.selectedPreSeason,
          weekType: "even",
        };
        ServerConnection.post("data/PreSeasonLeague", JSON.stringify(newPreSeasonLeague))
          .then((response) => {
            if (response.status != 201) {
              return Promise.reject(response);
            }
          })
          .then(() => this.loadSeasons(true))
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    savePreSeasonLeague(psl) {
      psl.newData.preSeason = this.selectedPreSeason;
      psl.newData.weekType = psl.newData.weekType.value;
      ServerConnection.put(
        "data/PreSeasonLeague/" + psl.newData.id,
        JSON.stringify(psl.newData)
      )
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response);
          }
        })
        .then(() => this.loadSeasons(true))
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    deletePreSeasonLeague(psl) {
      ServerConnection.delete("data/PreSeasonLeague/" + psl.id)
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response);
          }
        })
        .then(() => this.loadSeasons(true))
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    teamInLeagueTemplateChanged() {
      if (
        this.selectedPreSeasonLeagueTemplate &&
        this.selectedPreSeasonLeagueTemplate.id
      ) {
        this.teamsPickList[0].sort((t1, t2) => t1.name.localeCompare(t2.name));
        this.teamsPickList[1].sort((t1, t2) => t1.name.localeCompare(t2.name));
        this.selectedPreSeasonLeagueTemplate.teams = this.teamsPickList[1];
        this.selectedPreSeasonLeagueTemplate.preSeason = {
          id: this.selectedPreSeason.id,
        };
        ServerConnection.put(
          "data/PreSeasonLeagueTemplate/" + this.selectedPreSeasonLeagueTemplate.id,
          JSON.stringify(this.selectedPreSeasonLeagueTemplate)
        )
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response);
            }
          })
          .then(() => this.selectedLeagueChanged())
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    selectedLeagueTemplateChanged() {
      if (!this.teamsPickList) {
        this.teamsPickList = [[], []];
      }
      if (
        this.selectedPreSeasonLeagueTemplate &&
        this.selectedPreSeasonLeagueTemplate.id
      ) {
        this.teamsPickList[1] = this.selectedPreSeasonLeagueTemplate.teams;
      }
    },
    teamInLeagueChanged() {
      if (this.selectedPreSeasonLeague && this.selectedPreSeasonLeague.id) {
        this.teamsPickList[0].sort((t1, t2) => t1.name.localeCompare(t2.name));
        this.teamsPickList[1].sort((t1, t2) => t1.name.localeCompare(t2.name));
        this.selectedPreSeasonLeague.teams = this.teamsPickList[1];
        this.selectedPreSeasonLeague.preSeason = {
          id: this.selectedPreSeason.id,
        };
        ServerConnection.put(
          "data/PreSeasonLeague/" + this.selectedPreSeasonLeague.id,
          JSON.stringify(this.selectedPreSeasonLeague)
        )
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response);
            }
          })
          .then(() => this.selectedLeagueChanged())
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    selectedLeagueChanged() {
      if (!this.teamsPickList) {
        this.teamsPickList = [[], []];
      }
      if (this.selectedPreSeasonLeague && this.selectedPreSeasonLeague.id) {
        var allTeams = [];
        var assignedTeamIds = [];
        this.selectedPreSeason.preSeasonLeagueTemplates
          .filter((pslt) => pslt.prefix == this.selectedPreSeasonLeague.prefix)
          .forEach((pslt) => pslt.teams.forEach((team) => allTeams.push(team)));
        this.selectedPreSeason.preSeasonLeagues
          .filter((psl) => psl.prefix == this.selectedPreSeasonLeague.prefix)
          .forEach((psl) => psl.teams.forEach((team) => assignedTeamIds.push(team.id)));
        var unassignedTeams = allTeams.filter(
          (team) => assignedTeamIds.indexOf(team.id) < 0
        );
        this.teamsPickList[0] = unassignedTeams;
        this.teamsPickList[0].sort((a, b) => a.name.localeCompare(b.name));
        this.teamsPickList[1] = this.selectedPreSeasonLeague.teams;
        this.teamsPickList[1].sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    selectedTeamChanged() {
      this.loadTeamCriterias();
    },
    numberOfLeaguesChanged(preSeasonLeagueTemplate) {
      ServerConnection.put(
        "data/PreSeasonLeagueTemplate/" + preSeasonLeagueTemplate.id,
        JSON.stringify(preSeasonLeagueTemplate)
      )
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response);
          }
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    addCriteria() {
      this.selectedTeamCriteriaFieldDescription = {};
      this.currentTeamCriteria = {};
      this.currentTeamCriteria.preSeasonId = this.selectedPreSeason.id;
      this.currentTeamCriteria.teamId = this.selectedTeam.id;
      this.displayCriteriaDialog = true;
    },
    selectedTeamCriteriaFieldDescriptionChanged() {
      if (!this.currentTeamCriteria.id) {
        this.currentTeamCriteria.priority = this.selectedTeamCriteriaFieldDescription.defaultPriority;
      }
    },
    closeCriteriaDialog(save) {
      if (
        save &&
        this.selectedTeamCriteriaFieldDescription &&
        this.selectedTeamCriteriaFieldDescription.type
      ) {
        this.currentTeamCriteria.type = this.selectedTeamCriteriaFieldDescription.type;
        var parameters = "";
        this.selectedTeamCriteriaFieldDescription.fieldDescriptions.forEach((fd) => {
          if (
            this.currentTeamCriteria[fd.name] &&
            this.currentTeamCriteria[fd.name].length > 0
          ) {
            if (parameters.length > 0) {
              parameters += ",";
            }
            parameters += fd.name + "=" + this.currentTeamCriteria[fd.name];
          }
        });
        this.currentTeamCriteria.parameters = parameters;
        if (this.currentTeamCriteria.id) {
          ServerConnection.put(
            "data/preSeasonTeamCriteria/" + this.currentTeamCriteria.id,
            JSON.stringify(this.currentTeamCriteria)
          )
            .then((response) => {
              if (response.status != 200) {
                return Promise.reject(response);
              }
            })
            .then(() => {
              this.currentTeamCriteria = {};
              this.loadTeamCriterias();
            })
            .catch((error) => {
              FailureHandler.handleServerFailure(this.$router, error);
            });
        } else {
          ServerConnection.post(
            "data/preSeasonTeamCriteria",
            JSON.stringify(this.currentTeamCriteria)
          )
            .then((response) => {
              if (response.status != 201) {
                return Promise.reject(response);
              }
            })
            .then(() => {
              this.currentTeamCriteria = {};
              this.loadTeamCriterias();
            })
            .catch((error) => {
              FailureHandler.handleServerFailure(this.$router, error);
            });
        }
      }
      this.displayCriteriaDialog = false;
    },
    getLabelForCriteriaType(criteria) {
      var fieldDescriptions = this.allPreSeasonTeamCriteriaFieldDescriptions.filter(
        (fd) => fd.type == criteria.type
      );
      if (fieldDescriptions && fieldDescriptions.length > 0) {
        return (
          fieldDescriptions[0].label +
          this.getParameterLabelForCriteria(criteria, fieldDescriptions[0])
        );
      }
      return "Unbekannt";
    },
    getParameterLabelForCriteria(criteria, fieldDescription) {
      var paramLabel = "";
      fieldDescription.fieldDescriptions.forEach((fd) => {
        if (criteria[fd.name] && criteria[fd.name].length > 0) {
          if (fd.fieldType == "Enum") {
            var enumValue = fd.enumValues.find(
              (enumValue) => enumValue.value == criteria[fd.name]
            );
            if (enumValue && enumValue.value) {
              if (paramLabel.length > 0) {
                paramLabel += ", ";
              }
              paramLabel += enumValue.label;
            }
          } else if (fd.fieldType == "Date") {
            var value = new Date(criteria[fd.name]);
            var day = value.getDate();
            var month = value.getMonth() + 1;
            var year = value.getFullYear();
            if (paramLabel.length > 0) {
              paramLabel += ", ";
            }
            paramLabel +=
              (day < 10 ? "0" + day : day) +
              "." +
              (month < 10 ? "0" + month : month) +
              "." +
              year;
          } else {
            if (paramLabel.length > 0) {
              paramLabel += ", ";
            }
            paramLabel += criteria[fd.name];
          }
        }
      });
      if (paramLabel.length > 0) {
        return " (" + paramLabel + ")";
      }
      return paramLabel;
    },
    editCriteria(criteria) {
      var fieldDescriptions = this.allPreSeasonTeamCriteriaFieldDescriptions.filter(
        (fd) => fd.type == criteria.type
      );
      if (fieldDescriptions && fieldDescriptions.length > 0) {
        this.selectedTeamCriteriaFieldDescription = fieldDescriptions[0];
      }
      this.currentTeamCriteria = criteria;
      this.displayCriteriaDialog = true;
    },
    deleteCriteria(criteria) {
      this.criteriaToDelete = criteria;
      this.showDeleteCriteriaDialog = true;
    },
    executeDeleteCriteria() {
      if (this.criteriaToDelete && this.criteriaToDelete.id) {
        ServerConnection.delete(
          "data/preSeasonTeamCriteria/" + this.criteriaToDelete.id,
          JSON.stringify(this.currentTeamCriteria)
        )
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response);
            }
          })
          .then(() => {
            this.loadTeamCriterias();
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
      this.showDeleteCriteriaDialog = false;
    },
    cancelDeleteCriteria() {
      this.showDeleteCriteriaDialog = false;
      this.criteriaToDelete = null;
    },
    updateMenus() {
      const menuService = useMenuService();
      menuService.setSidebarVisisble(false);
      menuService.setMainMenuItemNames(["home"]);
    },
    updateScheduleStart(createNew) {
      var url = "preSeason/" + this.selectedPreSeason.id + "/schedules";

      if (createNew && this.firstScheduleStart && this.secondScheduleStart) {
        url +=
          "?firstScheduleStart=" +
          this.firstScheduleStart.toISOString().split("T")[0] +
          "&secondScheduleStart=" +
          this.secondScheduleStart.toISOString().split("T")[0];
      }
      ServerConnection.get(url)
        .then((response) => response.data)
        .then((data) => {
          this.gameDays = data;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    changeGameDay(gameDay) {
      this.currentGameDay = gameDay;
      var dateString = gameDay.date;
      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      this.currentGameDay.dateValue = new Date(dateString.replace(pattern, "$3-$2-$1"));
      this.showGameDayDialog = true;
    },
    closeGameDayDialog(save) {
      if (save) {
        this.currentGameDay.date = new Date(
          this.currentGameDay.dateValue.getTime() + 12 * 60 * 60 * 1000
        );
        ServerConnection.put(
          "preSeason/" +
            this.selectedPreSeason.id +
            "/" +
            this.currentGameDay.group +
            "/gameDay",
          JSON.stringify({
            number: this.currentGameDay.number,
            newDate: this.currentGameDay.date.toISOString().split("T")[0],
            moveCause: this.currentGameDay.moveCause,
          })
        )
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response);
            }
          })
          .then(() => {
            this.currentGameDay = {};
            this.updateScheduleStart(false);
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      } else {
        this.currentGameDay = {};
      }
      this.showGameDayDialog = false;
    },
    changeScheduleCalculationStatus(newStatus) {
      ServerConnection.put(
        "preSeason/" +
          this.selectedPreSeason.id +
          "/scheduleCalculation/status?newStatus=" +
          newStatus
      )
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response);
          }
        })
        .then(() => {
          if (newStatus == "start") {
            this.continueLoadingCalculationStatus = true;
            this.loadScheduleCalculationStatus();
          }
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    loadScheduleCalculationStatus() {
      ServerConnection.get(
        "preSeason/scheduleCalculation/status?lastStatusTime=" + this.lastStatusTime
      )
        .then((response) => response.data)
        .then((data) => {
          this.lastStatusTime = Date.now();
          this.scheduleCalculationStatus = data;
          if (
            this.continueLoadingCalculationStatus &&
            this.scheduleCalculationStatus.status == "RUNNING"
          ) {
            this.loadScheduleCalculationStatus();
          }
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    selectedScheduleChanged() {
      this.currentSchedule = {};
      ServerConnection.get(
        "preSeason/scheduleCalculation/" +
          this.selectedSchedule.id +
          "?structureType=flat"
      )
        .then((response) => response.data)
        .then((data) => {
          this.currentSchedule = data;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    gameDayRowStyle(data) {
      if (data.group == 1) {
        return { background: "lightblue" };
      }
      return { background: "lightgray" };
    },
  },
};
</script>

<style>
.input-class {
  width: 40px !important;
}

.vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
  padding: 4px;
}
.vuecal--month-view .vuecal__no-event {
  display: none;
}
.vuecal__event.one {
  background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46);
  color: #fff;
}
.vuecal__event.two {
  background-color: rgba(255, 102, 102, 0.9);
  border: 1px solid rgb(235, 82, 82);
  color: #fff;
}
</style>
