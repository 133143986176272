export const teamFieldDescriptions = [
  {
    name: "name",
    label: "Name",
    fieldType: "String",
  },
  {
    name: "active",
    label: "Aktiv",
    fieldType: "Boolean",
  },
];
export const teamLinkDescriptions = [
  {
    linkedRecordType: "association",
    recordField: "association",
    label: "Verein",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "location",
    recordField: "location",
    label: "Spielort",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "contactperson",
    recordField: "primaryContact",
    label: "Ansprechpartner/Kapitän",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "contactperson",
    recordField: "secondaryContact",
    label: "Stellv. Ansprechpartner",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "league",
    recordField: "league",
    label: "Liga",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "player",
    recordField: "players",
    label: "Spieler",
    linkTextRecordFieldName: "representation",
  }
  ,
];
