<template>
  <div class="flex align-items-center justify-content-center">
    <tab-menu
      v-if="teamAdminMenuItems && teamAdminMenuItems.length > 0"
      :model="teamAdminMenuItems"
      :activeIndex="activeMenuItem ? activeMenuItem.index : -1"
      style="margin-top: 10px; min-width: 200px; display: flex; justify-content: center"
    >
      <template #item="{ item }">
        <a
          class="p-menuitem-link"
          @click="onItemClick(item)"
          style="display: flex; flex-direction: column; padding: 5px 10px"
        >
          <span :class="item.icon" style="font-size: 38px" v-if="item.icon"></span>
          <span class="p-menuitem-text" style="margin-top: 8px; font-size: 12 px">{{
            item.label
          }}</span>
        </a>
      </template>
    </tab-menu>
  </div>
  <div>
    <div
      class="flex flex-wrap align-items-center justify-content-center"
      style="margin-top: 20px; width: 100%"
    >
      <div v-if="activeMenuItem && activeMenuItem.name == 'login' && user">
        <div class="card">
          <div class="field grid">
            <label for="name" class="col-fixed" style="width: 130px">Benutzername</label>
            <InputText id="name" v-model="user.username" />
          </div>
          <div class="field grid">
            <label for="password" class="col-fixed" style="width: 130px">Passwort</label>
            <Password id="password" v-model="user.password" :feedback="false"> </Password>
          </div>
          <Button label="Ändern" class="mt-2" @click="changeLogin()" />
        </div>
        <Dialog v-model:visible="showChangeLogInDialog" :closable="false">
          <template #header>
            <h3>Zugangsdaten ändern</h3>
          </template>
          Sollen ihre Zugangsdaten wirklich geändert werden? <br />
          Sie müssen sich danach neu anmelden.
          <template #footer>
            <Button
              label="Ja"
              icon="pi pi-times"
              class="p-button-text"
              @click="executeChangeLogin()"
            />
            <Button label="Abbrechen" icon="pi pi-check" @click="cancelChangeLogin()" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import TabMenu from "primevue/tabmenu";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
export default {
  name: "AdminSettings",
  components: {
    TabMenu,
    InputText,
    Password,
    Button,
    Dialog,
  },
  created() {
    this.updateData();
  },
  updated() {
    this.updateData();
  },
  data() {
    return {
      teamAdminMenuItems: [
        {
          index: 0,
          name: "login",
          label: "Zugangsdaten",
          icon: "pi pi-fw pi-sign-in",
          style: "padding: 0px 1px",
        },
      ],
      activeMenuItem: null,
      user: null,
      showChangeLogInDialog: false,
    };
  },
  props: {},
  methods: {
    updateData() {
      const menuService = useMenuService();
      menuService.setSidebarVisisble(false);
      menuService.setMainMenuItemNames([
        {
          name: "return",
          command: () => {
            if (window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }
          },
        },
      ]);
      if (this.user == null || this.user.userName == "") {
        var loggedInUser = JSON.parse(localStorage.getItem("user"));
        this.user = {
          username: loggedInUser.username,
          password: "DummyPassword",
        };
      }
      if (this.activeMenuItem == null) {
        this.activeMenuItem = this.teamAdminMenuItems[0];
      }
    },
    changeLogin() {
      this.showChangeLogInDialog = true;
    },
    executeChangeLogin() {
      this.showChangeLogInDialog = false;
      var changeLoginRequest = {
        newUsername: this.user.username,
        newPassword: this.user.password != "DummyPassword" ? this.user.password : "",
      };
      ServerConnection.put("changeLogin", changeLoginRequest)
        .then(() => {
          this.$store.dispatch("auth/logout").then(() => this.$router.push("/login"));
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    cancelChangeLogin() {
      this.showChangeLogInDialog = false;
      this.user = null;
      this.updateData();
    },
    onItemClick(item) {
      this.activeMenuItem = item;
    },
  },
};
</script>

<style lang="scss" scoped></style>
