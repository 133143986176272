import ServerConnection from "../ServerConnection/ServerConnection";
import FailureHandler from "../ServerConnection/FailureHandler";

class SettingsService {

  settings = null;

  getSettings(){
    if(this.settings == null){
      this.settings = this.loadSettings();
    }
    return this.settings;
  }

  loadSettings() {
    return ServerConnection.get("user/settings")
      .then(response => {
        var settings = {};
        response.data.filter(item => item && item.settingKey && item.settingKey.length > 0
          && item.settingValue && item.settingValue.length > 0).forEach(item => {
          settings[item.settingKey] = item.settingValue;
        });
        return settings;
      }).catch((error) => {
        FailureHandler.handleServerFailure(this.$router, error);
      });
  }

  saveSetting(settingKey, settingValue){
    this.settings = null;
    return ServerConnection.post("user/settings?key="+settingKey+"&value=" + settingValue)
    .then(() => {
      return this.getSettings();
  });
  }
}

import {PrimeVueSettingsServiceSymbol} from './UseSettingsService';
export default {
  install: (app) => {
    app.provide(PrimeVueSettingsServiceSymbol, new SettingsService());
  }
}
