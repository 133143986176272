<template>
  <div v-if="recordType == 'game'">
    <game-view :gameId="recordId" mode="view" />
  </div>
  <div v-else>
    <div>
      <h3>{{ getDisplayname() }}</h3>
    </div>
    <div class="card" style="margin: 10px 10px">
      <div
        class="flex flex-wrap card-container align-items-center justify-content-center"
      >
        <div style="width: 1200px">
          <div
            class="flex flex-wrap card-container align-items-center justify-content-center"
          >
            <div
              v-for="recordFieldDescription in recordFieldDescriptions"
              v-bind:key="recordFieldDescription.name + ':' + Math.random()"
            >
              <RecordField
                v-model:record="record"
                :recordFieldDescription="recordFieldDescription"
                :editMode="editMode"
                :disabled="isFieldDisabled(recordFieldDescription.name)"
              />
            </div>
          </div>
          <div v-if="showLinks">
            <div
              v-for="linkFieldDescription in linkFieldDescriptions"
              v-bind:key="linkFieldDescription.label"
            >
              <RecordLink
                :linkDescription="linkFieldDescription"
                :record="record"
                :collapsed="linkFieldDescriptions.length > 1"
              />
            </div>
          </div>
          <admin-game-day-view
            v-if="record && recordType == 'team'"
            :mannschaft="record"
            style="margin-top: 15px"
          />
          <admin-game-day-view
            v-if="record && recordType == 'league'"
            :league="record"
            style="margin-top: 15px"
          />
          <admin-game-day-view
            v-if="record && recordType == 'gameday'"
            :league="record.league"
            :gameDayNumber="record.numberOfGameDay"
            :collapsed="false"
            style="margin-top: 15px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import { useMenuService } from "../../services/MenuService/UseMenuService";
import { useWizardService } from "../../services/WizardService/UseWizardService";
import GameView from "./game/GameView";
import AdminGameDayView from "../admin/AdminGameDayView.vue";
import RecordLink from "./RecordLink";
import RecordField from "./RecordField";
import { playerFieldDescriptions, playerLinkDescriptions } from "./descriptions/player";
import { teamFieldDescriptions, teamLinkDescriptions } from "./descriptions/team";
import {
  associationFieldDescriptions,
  associationLinkDescriptions,
} from "./descriptions/association";
import {
  locationFieldDescriptions,
  locationLinkDescriptions,
} from "./descriptions/location";
import { seasonFieldDescriptions, seasonLinkDescriptions } from "./descriptions/season";
import { leagueFieldDescriptions, leagueLinkDescriptions } from "./descriptions/league";
import {
  contactpersonFieldDescriptions,
  contactpersonLinkDescriptions,
} from "./descriptions/contactperson";
import { gameFieldDescriptions, gameLinkDescriptions } from "./descriptions/game";
import {
  gameDaysFieldDescriptions,
  gameDayLinkDescriptions,
} from "./descriptions/gameday";
export default {
  name: "RecordView",
  components: {
    RecordLink,
    RecordField,
    GameView,
    AdminGameDayView,
  },
  props: {
    recordType: String,
    recordId: [String, Number],
    showLinks: {
      type: Boolean,
      default: true,
    },
    fieldsList: {
      type: Array,
      required: false,
    },
    disabledFields: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      record: {},
      editMode: this.editMode,
      menuService: useMenuService(),
      wizardService: useWizardService(),
      oldRecordId: null,
      oldRecordType: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    recordFieldDescriptions() {
      var fieldDescriptions = [];
      switch (this.recordType) {
        case "player":
          fieldDescriptions = playerFieldDescriptions;
          break;
        case "team":
          fieldDescriptions = teamFieldDescriptions;
          break;
        case "association":
          fieldDescriptions = associationFieldDescriptions;
          break;
        case "location":
          fieldDescriptions = locationFieldDescriptions;
          break;
        case "season":
          fieldDescriptions = seasonFieldDescriptions;
          break;
        case "league":
          fieldDescriptions = leagueFieldDescriptions;
          break;
        case "contactperson":
          fieldDescriptions = contactpersonFieldDescriptions;
          break;
        case "game":
          fieldDescriptions = gameFieldDescriptions;
          break;
        case "gameday":
          fieldDescriptions = gameDaysFieldDescriptions;
          break;
        default:
          throw new Error("invalid record type " + this.recordType);
      }
      if (this.fieldsList && this.fieldsList.length > 0) {
        return fieldDescriptions.filter(
          (fd) => this.fieldsList.filter((fieldName) => fieldName == fd.name).length > 0
        );
      }
      return fieldDescriptions;
    },
    linkFieldDescriptions() {
      switch (this.recordType) {
        case "player":
          return playerLinkDescriptions;
        case "team":
          return teamLinkDescriptions;
        case "association":
          return associationLinkDescriptions;
        case "location":
          return locationLinkDescriptions;
        case "season":
          return seasonLinkDescriptions;
        case "league":
          return leagueLinkDescriptions;
        case "contactperson":
          return contactpersonLinkDescriptions;
        case "game":
          return gameLinkDescriptions;
        case "gameday":
          return gameDayLinkDescriptions;
        default:
          throw new Error("invalid record type " + this.recordType);
      }
    },
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.loadRecord();
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.loadRecord();
    this.updateMenus();
  },
  methods: {
    updateMenus() {
      var sidebarItems = [];
      switch (this.recordType) {
        case "player":
          sidebarItems.push({
            label: "Mannschaft wechseln",
            icon: "pi pi-link",
            command: () => {
              this.wizardService.navigateToWizard(
                this.$router,
                "changeTeam",
                {
                  baseRecord: this.record,
                },
                this.$route.fullPath
              );
            },
          });
          break;
        case "game":
          sidebarItems.push({
            label: "Heimrecht tauschen",
            icon: "pi pi-link",
            command: () => {
              this.wizardService.navigateToWizard(
                this.$router,
                "changeHomeGuest",
                {
                  baseRecord: this.record,
                },
                this.$route.fullPath
              );
            },
          });
          break;
        case "team":
          sidebarItems.push({
            label: "Anderer Spielort",
            icon: "pi pi-link",
            command: () => {
              this.wizardService.navigateToWizard(
                this.$router,
                "changeLocation",
                {
                  baseRecord: this.record,
                },
                this.$route.fullPath
              );
            },
          });
      }
      sidebarItems.push({
        label: "Lösche " + this.getDisplayname(),
        icon: "pi pi-trash",
        command: () => {
          this.$toast.add({
            severity: "warn",
            summary: "Gelöscht",
            detail: "Datensatz gelöscht (nicht wirklich)",
            life: 3000,
          });
        },
      });
      sidebarItems.push({
        label: "Zeige Historie",
        icon: "pi pi-history",
        command: () => {
          this.$router.push("/history/" + this.recordType + "/" + this.recordId);
        },
      });
      this.menuService.setSidebarItems(sidebarItems);
      if (this.editMode) {
        this.menuService.setMainMenuItemNames([
          "home",
          {
            name: "save",
            command: () => {
              if (this.editMode) {
                this.saveRecord();
              }
              this.switchEditMode(false);
              this.updateMenus();
            },
          },
          {
            name: "cancel",
            command: () => {
              this.switchEditMode(false);
              this.updateMenus();
            },
          },
        ]);
      } else {
        this.menuService.setMainMenuItemNames([
          "home",
          "sidebar",
          {
            name: "edit",
            command: () => {
              this.switchEditMode(true);
              this.updateMenus();
            },
          },
        ]);
      }
    },
    reloadRecord() {
      this.oldRecordId = -1;
      this.loadRecord();
    },
    loadRecord() {
      if (this.recordId != this.oldRecordId || this.recordType != this.oldRecordType) {
        if (this.oldRecordType != this.recordType) {
          this.updateMenus();
        }
        this.oldRecordType = this.recordType;
        this.oldRecordId = this.recordId;
        this.switchEditMode(false);
        ServerConnection.get("data/" + this.recordType + "/" + this.recordId)
          .then((response) => (this.record = response.data))
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    saveRecord() {
      ServerConnection.put(
        "data/" + this.recordType + "/" + this.recordId,
        JSON.stringify(this.record)
      )
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response);
          }
        })
        .then(() => {
          this.oldRecordId = null;
          this.loadRecord();
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    switchEditMode(newEditMode) {
      if (this.editMode != newEditMode) {
        this.editMode = newEditMode;
      }
    },
    isFieldDisabled(recordFieldName) {
      if (this.disabledFields) {
        return (
          this.disabledFields.filter((disabledField) => disabledField == recordFieldName)
            .length > 0
        );
      }
      return false;
    },
    getDisplayname() {
      switch (this.recordType) {
        case "player":
          return "Spieler";
        case "team":
          return "Mannschaft";
        case "association":
          return "Verein";
        case "location":
          return "Spielort";
        case "season":
          return "Saison";
        case "league":
          return "Liga";
        case "contactperson":
          return "Ansprechpartner";
        case "game":
          return "Spiel";
        case "gameday":
          return "Spieltag";
        default:
          return "Unbekannt";
      }
    },
  },
};
</script>
