<template>
  <Toast />
  <div style="justify-content: center">
    <div style="display: block">
      <main-menu v-if="loggedIn" />
      <router-view />
    </div>
  </div>
</template>

<script>
import MainMenu from "./components/MainMenu";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  name: "App",
  components: {
    MainMenu,
    Toast,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  setup() {
    const toast = useToast();
    toast.add({
      severity: "info",
      summary: "Info Message",
      detail: "Message Content",
      life: 3000,
    });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
.p-sidebar .p-sidebar-content {
  padding: 2px !important;
}
</style>
