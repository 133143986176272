import axios from 'axios';
import authHeader from '../AuthenticationService/AuthenticationHeader';
const API_URL = "/api/";

class ServerConnection {

    get(url){
       var headers = authHeader();
       return axios.get(API_URL + url, { headers: headers });
    }
    post(url, body){
        var headers = authHeader();
        headers['Content-Type'] = "application/json";
        return axios.post(API_URL + url, body, { headers: headers });
    }
    postFile(url, file, onUploadProgress){
        var headers = authHeader();
        headers['Content-Type'] = "multipart/form-data";
        let formData = new FormData();
        formData.append("file", file);
    
        return axios.post(API_URL + url, formData, {
          headers: headers,
          onUploadProgress
        });
    }
    put(url, body){
        var headers = authHeader();
        headers['Content-Type'] = "application/json";
        return axios.put(API_URL + url, body, { headers:  headers});
    }
    delete(url){
        var headers = authHeader();
        return axios.delete(API_URL + url, { headers:  headers});
    }
    
}
export default new ServerConnection();