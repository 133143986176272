<template>
  <div>
    <div class="card">
      <div class="flex flex-column card-container green-container">
        <div class="flex align-items-center justify-content-center">
          <div
            class="flex flex-wrap card-container bg-blue-100 border-round"
            style="width: 100%; max-width: 800px; margin: 5px 1px"
          >
            <div
              class="flex-grow-1 flex align-items-center justify-content-center"
              style="width: 250px; padding: 10px"
            >
              <div class="p-float" style="text-align: left; width: 100%">
                <label
                  style="
                    margin: 5px 1px;
                    font-size: 15px;
                    text-decoration: underline black;
                  "
                >
                  Bisheriger Verein </label
                ><br />
                <label style="font-size: 18px">{{
                  (recordModel.baseRecord.association &&
                    recordModel.baseRecord.association.name) ??
                  "Keiner"
                }}</label>
              </div>
            </div>
            <div
              class="flex-grow-1 flex align-items-center justify-content-center"
              style="width: 300px; padding: 10px"
            >
              <div class="p-float" style="text-align: left; width: 100%">
                <label
                  style="
                    margin: 5px 1px;
                    font-size: 15px;
                    text-decoration: underline black;
                  "
                >
                  Neuer Verein </label
                ><br />
                <label style="margin: 5px 1px; font-size: 18px">
                  {{
                    this.recordModel.newVerein
                      ? this.recordModel.newVerein.representation
                      : (recordModel.baseRecord.association &&
                          recordModel.baseRecord.association.name) ??
                        "Keiner"
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center">
          <div
            class="flex flex-wrap card-container bg-blue-100 border-round"
            style="width: 100%; max-width: 800px; margin: 5px 1px"
          >
            <div
              class="flex-grow-1 flex align-items-center justify-content-center"
              style="width: 250px; padding: 10px"
            >
              <div class="p-float" style="text-align: left; width: 100%">
                <label
                  style="
                    margin: 5px 1px;
                    font-size: 15px;
                    text-decoration: underline black;
                  "
                >
                  Bisherige Mannschaft </label
                ><br />
                <label style="font-size: 18px">{{
                  (recordModel.baseRecord.mannschaft &&
                    recordModel.baseRecord.mannschaft[0].Name) ??
                  "Keine"
                }}</label>
              </div>
            </div>
            <div
              class="flex-grow-1 flex align-items-center justify-content-center"
              style="width: 300px; padding: 10px"
            >
              <div class="p-float" style="text-align: left; width: 100%">
                <label
                  style="
                    margin: 5px 1px;
                    font-size: 15px;
                    text-decoration: underline black;
                  "
                >
                  Neue Mannschaft </label
                ><br />
                <label style="margin: 5px 1px; font-size: 18px">
                  {{
                    this.recordModel.newMannschaft
                      ? this.recordModel.newMannschaft.representation
                      : (recordModel.baseRecord.mannschaft &&
                          recordModel.baseRecord.mannschaft[0].Name) ??
                        "Keine"
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractWizardPage from "../../AbstractWizardPage";
export default {
  name: "ChangeTeamPage2",
  extends: AbstractWizardPage,
  methods: {},
};
</script>

<style lang="scss" scoped></style>
