import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router';
import store from "./services/Store/Store";
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'
//import 'primevue/resources/themes/md-light-indigo/theme.css'
//import 'primevue/resources/themes/tailwind-light/theme.css'
//import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';

import Login from "./components/login/Login.vue";
import Logout from "./components/login/Logout.vue";
import Settings from "./components/settings/Settings.vue";
import Schedule from "./components/admin/schedule/Schedule.vue";
import HelpMain from "./components/help/HelpMain.vue";
import Failure from "./components/login/Failure.vue";
import DashBoard from "./components/DashBoard.vue";
import RecordView from "./components/records/RecordView.vue";
import NewRecordView from "./components/records/NewRecordView.vue";
import History from "./components/History.vue";
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';

import MenuService from './services/MenuService/MenuService';
import WizardService from './services/WizardService/WizardService';
import SettingsService from './services/SettingsService/SettingsService';
import Wizard from './components/wizard/Wizard';
import ChangeTeamPage1 from './components/wizardPages/admin/changeTeam/ChangeTeamPage1';
import ChangeTeamPage2 from './components/wizardPages/admin/changeTeam/ChangeTeamPage2';
import AddPlayerPage1 from './components/wizardPages/admin/addPlayer/AddPlayerPage1';
import ChangeHomeGuestPage1 from './components/wizardPages/admin/changeHomeGuest/ChangeHomeGuestPage1';
import ChangeLocationPage1 from './components/wizardPages/admin/changeLocation/ChangeLocationPage1';
import SetWinnerPage1 from './components/wizardPages/admin/setWinner/SetWinnerPage1';
import SwitchHomeGuestPage1 from './components/wizardPages/admin/switchHomeGuest/SwitchHomeGuestPage1';

import SwitchHomeGuestRequestPage1 from './components/wizardPages/teamadmin/switchHomeGuestRequest/SwitchHomeGuestRequestPage1';
import ChangeDateRequestPage1 from './components/wizardPages/teamadmin/changeDateRequest/ChangeDateRequestPage1';

import GameView from './components/records/game/GameView';
import GameEditView from './components/records/game/GameView';
import Tooltip from 'primevue/tooltip';
const routes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/help', component: HelpMain },
  { path: '/settings', component: Settings },
  { path: '/failure/:failureMessage', component: Failure, props: true },
  { path: '/', component: DashBoard },
  { path: '/home', component: DashBoard },
  { path: '/recordview/:recordType/:recordId', component: RecordView, props: true },
  { path: '/newrecord/:recordType/', component: NewRecordView, props: true },
  { path: '/history/:recordType/:recordId', component: History, props: true},
  { path: '/history/:recordType', component: History, props: true},
  { path: '/game/:gameId', component: GameView, props: true},
  { path: '/schedule', component: Schedule, props: false},
  { path: '/game/:mode/:gameId/', component: GameEditView, props: true},
  { path: '/wizard', component: Wizard, props:true,
    children : [
      {
      path: 'change_team_page1',
      component: ChangeTeamPage1,
      },
      {
        path: 'change_team_page2',
        component: ChangeTeamPage2,
      },
      {
        path: 'add_player_page1',
        component: AddPlayerPage1
      },
      {
        path: 'change_home_guest1',
        component: ChangeHomeGuestPage1
      },
      {
        path: 'change_location',
        component: ChangeLocationPage1
      },
      {
        path: 'set_winner',
        component: SetWinnerPage1
      },
      {
        path: 'switchHomeGuest',
        component: SwitchHomeGuestPage1
      },
      {
        path: 'switchHomeGuestRequest',
        component: SwitchHomeGuestRequestPage1
      },
      {
        path: 'changeDateRequest',
        component: ChangeDateRequestPage1
      }
     ]
  },
]
const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})
const app = createApp(App);
app.use(PrimeVue).use(router).use(store).use(MenuService).use(WizardService).use(ToastService).use(SettingsService)
.directive('badge', BadgeDirective).directive('tooltip', Tooltip)
.mount('#app');
