<template>
  <Fieldset
    legend="Letzten Spiele"
    :toggleable="true"
    :collapsed="fieldSetCollapsed"
    @toggle="toogleAdminLatestGamesView()"
    style="min-width: 300px"
  >
    <div class="flex flex-wrap" style="max-width: 1200px; margin-bottom: 10px">
      <Button
        class="flex align-items-center justify-content-center m-1 border-round"
        style="padding: 8px 5px"
        label="Alle offenen Spiele"
        @click="allOpenGames"
      />
      <Button
        class="flex align-items-center justify-content-center m-1 border-round"
        style="padding: 8px 5px"
        label="Alle unvollständigen Spiele"
        @click="allIncompleteGames"
      />
      <Button
        class="flex align-items-center justify-content-center m-1 border-round"
        style="padding: 8px 5px"
        label="Alle Filter löschen"
        @click="clearFilter"
      />
    </div>
    <div
      class="flex flex-wrap"
      style="max-width: 1200px; margin-bottom: 10px; border: 1px solid darkgray"
    >
      <ToggleButton
        class="flex align-items-center justify-content-center m-1 border-round"
        :style="'font-size: 14px; padding: 0px;' + (gs.active ? 'background:green' : '')"
        v-for="gs in gameStatusModel"
        v-bind:key="gs.id"
        v-model="gs.active"
        :onLabel="gs.label"
        :offLabel="gs.label"
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        @change="loadGameChanges"
      />
    </div>
    <DataTable
      class="p-datatable-sm"
      :value="latestGames"
      responsiveLayout="stack"
      breakpoint="550px"
    >
      <Column field="update" header="Letzte Änderung/Änderungsbenutzer" />
      <Column field="gameDay" header="Spieltag" />
      <Column field="representation" header="Spiel" />
      <Column header="Spielstatus">
        <template #body="slotProps">
          {{ translateGameStatus(slotProps.data) }}
        </template>
      </Column>
      <Column field="notes" header="Bemerkungen" />
      <Column header="">
        <template #body="slotProps">
          <Button
            style="margin: 2px 2px"
            icon="pi pi-eye"
            v-tooltip.top="'Details'"
            class="p-button-rounded p-button-success mb2"
            @click="showGame(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </Fieldset>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import Fieldset from "primevue/fieldset";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import ToggleButton from "primevue/togglebutton";

export default {
  name: "AdminLatestGamesView",
  components: {
    Fieldset,
    DataTable,
    Column,
    Button,
    ToggleButton,
  },
  mounted() {
    this.loadGameChanges();
  },
  updated() {
    this.loadGameChanges();
  },
  data() {
    return {
      fieldSetCollapsed: true,
      latestGames: [],
      gameStatusModel: [
        { id: "ToPlay", label: "Zu spielen", active: false },
        { id: "WaitingForResults", label: "Wird gerade gespielt", active: false },
        { id: "WaitingForImage", label: "Noch kein Endergebnis", active: false },
        { id: "InDiscussion", label: "In Abstimmung", active: false },
        {
          id: "WaitingForHomeApproval",
          label: "In Bestätigung durch Heim",
          active: false,
        },
        {
          id: "WaitingForGuestApproval",
          label: "In Bestätigung durch Gast",
          active: false,
        },
        {
          id: "WaitingForGameManagerApproval",
          label: "In Kontrolle durch Spielleiter",
          active: false,
        },
        { id: "Played", label: "Gespielt", active: false },
        { id: "PostPoned", label: "Verlegt", active: false },
        { id: "Unrated", label: "Keine Wertung", active: false },
        { id: "ChangeRequested", label: "Änderung beim Gegner angefragt", active: false },
      ],
    };
  },
  props: {},
  methods: {
    toogleAdminLatestGamesView() {
      this.fieldSetCollapsed = !this.fieldSetCollapsed;
    },
    showGame(data) {
      this.$router.push("/game/view/" + data.gameId);
    },
    loadGameChanges() {
      if (!this.fieldSetCollapsed) {
        var gameStatusFilter = this.gameStatusModel
          .filter((gs) => gs.active)
          .map((gs) => gs.id)
          .join(",");
        ServerConnection.get("admin/latestgames?gameStatusFilter=" + gameStatusFilter)
          .then((response) => (this.latestGames = response.data))
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    allOpenGames() {
      this.changeGameStatusToogles([
        "ToPlay",
        "BeingPlayed",
        "WaitingForResults",
        "WaitingForImage",
        "WaitingForHomeApproval",
        "InDiscussion",
        "PostPoned",
        "ChangeRequested",
      ]);
    },
    allIncompleteGames() {
      this.changeGameStatusToogles([
        "BeingPlayed",
        "WaitingForResults",
        "WaitingForImage",
        "WaitingForHomeApproval",
        "WaitingForGameManagerApproval",
        "InDiscussion",
      ]);
    },
    clearFilter() {
      this.changeGameStatusToogles([]);
    },
    changeGameStatusToogles(activeGameStatus) {
      this.gameStatusModel.forEach((gs) => (gs.active = false));
      this.gameStatusModel
        .filter((gs) => activeGameStatus.indexOf(gs.id) >= 0)
        .forEach((gs) => (gs.active = true));
      this.loadGameChanges();
    },
    translateGameStatus(game) {
      if (game && game.gameStatus) {
        switch (game.gameStatus) {
          case "ToPlay":
            return "Zu spielen";
          case "BeingPlayed":
            return "Wird gerade gespielt";
          case "WaitingForResults":
            return "Noch kein Endergebnis";
          case "WaitingForImage":
            return "Noch kein Bild des Endergebnisses";
          case "WaitingForHomeApproval":
            return "In Bestätigung durch Heim";
          case "InDiscussion":
            return "In Abstimmung";
          case "WaitingForGuestApproval":
            return "In Bestätigung durch Gast";
          case "WaitingForGameManagerApproval":
            return "In Kontrolle durch Spielleiter";
          case "Played":
            return "Gespielt";
          case "PostPoned":
            return "Verlegt";
          case "Unrated":
            return "Keine Wertung";
          case "ChangeRequested":
            return "Änderung beim Gegner angefragt";
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
