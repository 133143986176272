import { inject } from 'vue';

export const PrimeVueWizardServiceSymbol = Symbol(); 

export function useWizardService() { 
    const PrimeVueWizardService = inject(PrimeVueWizardServiceSymbol);
    if (!PrimeVueWizardService) {
        throw new Error('No PrimeVue WizardService provided!'); 
    } 
    return PrimeVueWizardService; 
}
