<script>
import Dropdown from "primevue/dropdown";
export default {
  name: "AbstractWizardPage",
  components: [Dropdown],
  data() {
    return {
      recordModel: this.wizardRecordModel,
    };
  },
  props: {
    wizardRecordModel: {},
  },
  updated() {},
  methods: {
    updateModel() {
      this.$emit("update:wizardRecordModel", this.recordModel);
    },
    validate() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
