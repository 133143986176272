class FailureHandler {
    handleServerFailure(router, error){
        console.log("Error:" + error.stack);
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401 || error.code == "ERR_NETWORK") {
            localStorage.removeItem('user');
        }
        var failureCode = this.convertErrorToFailureCode(error);
        if(this.routeToFailureSide(failureCode)){
            if(router){
                router.push("/failure/"+this.translateFailureCode(failureCode));
            }else if(this.$router){
                this.$router.push("/failure/"+this.translateFailureCode(failureCode));
            }else{
                console.log("No router found");
            }
        }
    }
    convertErrorToFailureCode(error){
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401){
            return 401;
        }
        if(error.code == "ERR_NETWORK"){
            return 503;
        }
        return 500;
    }
    translateFailureCode(failureCode) {

        switch(failureCode){
            case 401: return "Anmeldung abgelaufen! Bitte erneut anmelden."
            case 503: return "Der Server is aktuell nicht verfügbar! Bitte versuchen Sie es später."
        }
        return "Unbekannter Fehler! Bitte wenden Sie sich an den Administrator."
    }
    routeToFailureSide(failureCode){
        switch(failureCode){
            case 401:
            case 503:
                return true;
        }
        return false;
    }
}
export default new FailureHandler();