<template>
  <div>
    <h3>{{ getDisplayname() }}</h3>
  </div>
  <div class="card" style="margin: 10px 10px">
    <div class="flex flex-wrap card-container align-items-center justify-content-center">
      <div style="width: 1200px">
        <div
          class="flex flex-wrap card-container align-items-center justify-content-center"
        >
          <div
            v-for="recordFieldDescription in recordFieldDescriptions"
            v-bind:key="recordFieldDescription.name + ':' + Math.random()"
          >
            <RecordField
              v-model:record="record"
              :recordFieldDescription="recordFieldDescription"
              :editMode="true"
              :disabled="isFieldDisabled(recordFieldDescription.name)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import { useMenuService } from "../../services/MenuService/UseMenuService";
import RecordField from "./RecordField";
import { locationFieldDescriptions } from "./descriptions/location";
import { contactpersonFieldDescriptions } from "./descriptions/contactperson";
import { teamFieldDescriptions } from "./descriptions/team";
import { associationFieldDescriptions } from "./descriptions/association";
export default {
  name: "NewRecordView",
  components: {
    RecordField,
  },
  props: {
    recordType: String,
    fieldsList: {
      type: Array,
      required: false,
    },
    disabledFields: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      record: {},
      menuService: useMenuService(),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    recordFieldDescriptions() {
      var fieldDescriptions = [];
      switch (this.recordType) {
        case "team":
          fieldDescriptions = teamFieldDescriptions;
          break;
        case "location":
          fieldDescriptions = locationFieldDescriptions;
          break;
        case "contactperson":
          fieldDescriptions = contactpersonFieldDescriptions;
          break;
        case "association":
          fieldDescriptions = associationFieldDescriptions;
          break;
        default:
          throw new Error("invalid record type " + this.recordType);
      }
      if (this.fieldsList && this.fieldsList.length > 0) {
        return fieldDescriptions.filter(
          (fd) => this.fieldsList.filter((fieldName) => fieldName == fd.name).length > 0
        );
      }
      return fieldDescriptions;
    },
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.updateMenus();
  },
  methods: {
    updateMenus() {
      var sidebarItems = [];
      this.menuService.setSidebarItems(sidebarItems);
      this.menuService.setMainMenuItemNames([
        "home",
        {
          name: "save",
          command: () => {
            this.saveRecord();
            this.$router.push("/recordview/" + this.recordType + "/" + this.record.id);
          },
        },
        {
          name: "cancel",
          command: () => {
            this.$router.go(-1);
          },
        },
      ]);
    },

    saveRecord() {
      ServerConnection.post("data/" + this.recordType, JSON.stringify(this.record))
        .then((response) => {
          if (response.status != 201) {
            return Promise.reject(response);
          }
        })
        .then((response) => {
          this.record = response.data;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    isFieldDisabled(recordFieldName) {
      if (this.disabledFields) {
        return (
          this.disabledFields.filter((disabledField) => disabledField == recordFieldName)
            .length > 0
        );
      }
      return false;
    },
    getDisplayname() {
      switch (this.recordType) {
        case "team":
          return "Neue Mannschaft";
        case "location":
          return "Neuer Spielort";
        case "contactperson":
          return "Neuer Ansprechpartner";
        default:
          return "Unbekannt";
      }
    },
  },
};
</script>
