<template>
  <div class="card">
    <div class="flex flex-column card-container green-container">
      <div class="flex align-items-center justify-content-center">
        <div
          class="flex flex-wrap card-container bg-blue-100 border-round"
          style="width: 100%; max-width: 800px; margin: 5px 1px"
        >
          <div
            class="flex-grow-1 flex align-items-center justify-content-center"
            style="width: 250px; padding: 10px"
          >
            <div class="p-float" style="text-align: left; width: 100%">
              <label
                style="margin: 5px 1px; font-size: 15px; text-decoration: underline black"
              >
                Bisheriger Verein </label
              ><br />
              <label>{{
                (recordModel.baseRecord.association &&
                  recordModel.baseRecord.association.name) ??
                "Keiner"
              }}</label>
            </div>
          </div>
          <div
            class="flex-grow-1 flex align-items-center justify-content-center"
            style="width: 300px; padding: 10px"
          >
            <div class="p-float" style="text-align: left; width: 100%">
              <label
                style="margin: 5px 1px; font-size: 15px; text-decoration: underline black"
              >
                Neuer Verein </label
              ><br />
              <Dropdown
                v-model="selectedVereinsId"
                :options="vereine"
                :editable="true"
                optionLabel="representation"
                optionValue="id"
                @change="loadMannschaften"
                :filter="vereine.length >= 10"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex align-items-center justify-content-center">
        <div
          class="flex flex-wrap card-container bg-blue-100 border-round"
          style="width: 100%; max-width: 800px; margin: 5px 1px"
        >
          <div
            class="flex-grow-1 flex align-items-center justify-content-center"
            style="width: 250px; padding: 10px"
          >
            <div class="p-float" style="text-align: left; width: 100%">
              <label
                style="margin: 5px 1px; font-size: 15px; text-decoration: underline black"
              >
                Bisherige Mannschaft </label
              ><br />
              <label>{{
                (recordModel.baseRecord.team && recordModel.baseRecord.team.name) ??
                "Keine"
              }}</label>
            </div>
          </div>
          <div
            class="flex-grow-1 flex align-items-center justify-content-center"
            style="width: 300px; padding: 10px"
          >
            <div class="p-float" style="text-align: left; width: 100%">
              <label
                style="margin: 5px 1px; font-size: 15px; text-decoration: underline black"
              >
                Neue Mannschaft </label
              ><br />
              <Dropdown
                v-model="selectedMannschaftsId"
                :options="mannschaften"
                optionLabel="representation"
                optionValue="id"
                :filter="mannschaften.length >= 10"
                @change="changeMannschaft"
                style="width: 100%"
              />
              <small v-if="invalidState" class="p-error"
                >Es muss eine Mannschaft ausgewählt werden</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../../../services/ServerConnection/FailureHandler";
import AbstractWizardPage from "../../AbstractWizardPage";
import Dropdown from "primevue/dropdown";
export default {
  name: "ChangeTeamPage1",
  extends: AbstractWizardPage,
  components: {
    Dropdown,
  },
  data() {
    return {
      vereine: [],
      selectedVereinsId: {},
      mannschaften: [],
      selectedMannschaftsId: {},
      invalidState: false,
    };
  },
  created() {
    this.loadVereine();
    if (this.recordModel.newVerein) {
      this.selectedVereinsId = this.recordModel.newVerein.id;
    } else if (this.recordModel.baseRecord.association) {
      this.selectedVereinsId = this.recordModel.baseRecord.association.id;
    }
    this.loadMannschaften();
    if (this.recordModel.newMannschaft) {
      this.selectedMannschaftsId = this.recordModel.newMannschaft.id;
    } else if (this.recordModel.baseRecord.team) {
      this.selectedMannschaftsId = this.recordModel.baseRecord.team.id;
    }
  },
  updated() {},
  methods: {
    validate() {
      if (this.selectedMannschaftsId) {
        return true;
      } else {
        this.invalidState = true;
        return false;
      }
    },
    loadVereine() {
      ServerConnection.get("selection/association")
        .then((response) => response.data)
        .then((dataList) => {
          this.vereine = dataList;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    loadMannschaften() {
      if (this.selectedVereinsId) {
        ServerConnection.get("data/association/" + this.selectedVereinsId)
          .then((response) => response.data.teams)
          .then((dataList) => {
            this.mannschaften = dataList;
            if (this.mannschaften.length == 1) {
              this.selectedMannschaftsId = this.mannschaften[0].id;
              this.changeMannschaft();
            }
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
        if (this.selectedVereinsId != this.recordModel.baseRecord.association.id) {
          this.selectedMannschaftsId = null;
          this.vereine.forEach((verein) => {
            if (verein.id == this.selectedVereinsId) {
              this.recordModel.newVerein = verein;
            }
          });
        }
      }
    },
    changeMannschaft() {
      this.invalidState = false;
      if (
        !this.recordModel.baseRecord.mannschaft ||
        this.selectedMannschaftsId != this.recordModel.baseRecord.mannschaft[0].id
      ) {
        this.mannschaften.forEach((mannschaft) => {
          if (mannschaft.id == this.selectedMannschaftsId) {
            this.recordModel.newMannschaft = mannschaft;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
