<template>
  <div
    class="p-float"
    style="text-align: center; margin: 10px 20px"
    v-show="
      !editMode &&
      (internalRecord[recordFieldDescription.name] ||
        recordFieldDescription.fieldType == 'Boolean')
    "
  >
    <div class="flex flex-column card-container" style="margin: 5px 10px">
      <label
        class="align-items-center justify-content-center"
        style="margin: 5px 1px; font-size: 18px; text-decoration: underline"
        >{{ recordFieldDescription.label }}</label
      >
      <label
        v-if="recordFieldDescription.fieldType != 'Enum'"
        class="align-items-center justify-content-center"
        style="font-size: 20px"
        >{{ formattedValue }}</label
      >
      <label
        v-else
        class="align-items-center justify-content-center"
        style="font-size: 20px"
        >{{ getEnumLabel(recordFieldDescription) }}</label
      >
    </div>
  </div>
  <span v-show="editMode">
    <div
      class="p-float-label"
      style="margin: 30px 10px 10px 10px"
      v-if="recordFieldDescription.fieldType == 'Date'"
    >
      <Calendar
        v-model="dateValue"
        touchUI
        dateFormat="dd.mm.yy"
        :showTime="false"
        :showIcon="true"
        :manualInput="false"
        :disabled="disabled"
        @change="updateValue()"
        style="margin: 2px; width: 300px"
      />
      <label style="font-size: 18px; text-decoration: underline">{{
        recordFieldDescription.label
      }}</label>
    </div>
    <div
      class="p-float-label"
      style="margin: 30px 10px 10px 10px"
      v-else-if="recordFieldDescription.fieldType == 'DateTime'"
    >
      <Calendar
        v-model="dateTimeValue"
        touchUI
        dateFormat="dd.mm.yy"
        :showTime="true"
        :showIcon="true"
        :manualInput="false"
        :disabled="disabled"
        @update:modelValue="updateValue()"
        style="margin: 2px; width: 300px"
      />
      <label style="font-size: 18px; text-decoration: underline">{{
        recordFieldDescription.label
      }}</label>
    </div>
    <div
      class="p-float-label"
      style="margin: 30px 10px 10px 10px"
      v-else-if="recordFieldDescription.fieldType == 'Boolean'"
    >
      <SelectButton
        class="p-inputwrapper-filled"
        v-model="booleanValue"
        :options="['Ja', 'Nein']"
        :disabled="disabled"
      >
        <template #option="slotProps">
          <div style="min-width: 40px">
            <div>{{ slotProps.option }}</div>
          </div>
        </template>
      </SelectButton>
      <label id="booleanLabel" style="font-size: 18px; text-decoration: underline">{{
        recordFieldDescription.label
      }}</label>
    </div>
    <div
      class="p-float-label"
      style="margin: 30px 10px 10px 10px"
      v-else-if="recordFieldDescription.fieldType == 'Enum'"
    >
      <Dropdown
        v-model="internalRecord[recordFieldDescription.name]"
        :options="recordFieldDescription.enumValues"
        optionLabel="label"
        optionValue="value"
        :disabled="disabled"
        :style="minWidth ? 'min-width: ' + minWidth + 'px;' : ''"
      />
      <label style="font-size: 18px; text-decoration: underline">{{
        recordFieldDescription.label
      }}</label>
    </div>
    <div class="p-float-label" style="margin: 30px 10px 10px 10px" v-else>
      <InputText
        class="p-inputtext-lg"
        style="margin: 2px; width: 300px:"
        type="text"
        v-model="internalRecord[recordFieldDescription.name]"
        :disabled="disabled"
        @change="updateValue()"
      />
      <label style="font-size: 18px; text-decoration: underline">{{
        recordFieldDescription.label
      }}</label>
    </div>
  </span>
</template>

<script>
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
export default {
  name: "RecordField",
  components: {
    InputText,
    Calendar,
    SelectButton,
    Dropdown,
  },
  data() {
    return {
      internalRecord: this.record,
    };
  },
  computed: {
    formattedValue: {
      get() {
        var value = this.internalRecord[this.recordFieldDescription.name];
        if (this.recordFieldDescription.fieldType == "Boolean") {
          return this.booleanValue;
        }
        if (this.recordFieldDescription.fieldType == "Date") {
          if (!value || value.length < 0) {
            return null;
          }
          value = this.dateValue;

          var day = value.getDate();
          var month = value.getMonth() + 1;
          var year = value.getFullYear();
          return (
            (day < 10 ? "0" + day : day) +
            "." +
            (month < 10 ? "0" + month : month) +
            "." +
            year
          );
        }
        if (this.recordFieldDescription.fieldType == "DateTime") {
          if (!value || value.length < 0) {
            return null;
          }
          value = this.dateTimeValue;
          var day2 = value.getDate();
          var month2 = value.getMonth() + 1;
          var hour = value.getHours();
          var minutes = value.getMinutes();
          return (
            (day2 < 10 ? "0" + day2 : day2) +
            "." +
            (month2 < 10 ? "0" + month2 : month2) +
            "." +
            value.getFullYear() +
            " " +
            (hour < 10 ? "0" + hour : hour) +
            ":" +
            (minutes < 10 ? "0" + minutes : minutes)
          );
        }
        return value;
      },
    },
    dateValue: {
      get() {
        if (
          this.recordFieldDescription.fieldType == "Date" &&
          this.internalRecord[this.recordFieldDescription.name]
        ) {
          var dateString = this.internalRecord[this.recordFieldDescription.name];
          return new Date(dateString);
        }
        return "";
      },
      set(newValue) {
        var day = newValue.getDate();
        var month = newValue.getMonth() + 1;
        var year = newValue.getFullYear();
        var newDateString =
          year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day);
        this.internalRecord[this.recordFieldDescription.name] = newDateString;
        this.updateValue();
      },
    },
    dateTimeValue: {
      get() {
        if (
          this.recordFieldDescription.fieldType == "DateTime" &&
          this.internalRecord[this.recordFieldDescription.name]
        ) {
          var dateString = this.internalRecord[this.recordFieldDescription.name];
          return new Date(dateString);
        }
        return "";
      },
      set(newValue) {
        var day = newValue.getDate();
        var month = newValue.getMonth() + 1;
        var hour = newValue.getHours();
        var minutes = newValue.getMinutes();
        var newDateTimeString =
          newValue.getFullYear() +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day) +
          " " +
          (hour < 10 ? "0" + hour : hour) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes);
        this.internalRecord[this.recordFieldDescription.name] = newDateTimeString;
        this.updateValue();
      },
    },
    booleanValue: {
      get() {
        if (this.recordFieldDescription.fieldType == "Boolean") {
          var booleanValue = this.internalRecord[this.recordFieldDescription.name];
          return booleanValue ? "Ja" : "Nein";
        }
        return "";
      },
      set(newValue) {
        if ("Ja" == newValue) {
          this.internalRecord[this.recordFieldDescription.name] = true;
        } else if ("Nein" == newValue) {
          this.internalRecord[this.recordFieldDescription.name] = false;
        } else {
          throw new Error("Unexpected value for boolean field: " + newValue);
        }
      },
    },
  },
  updated() {
    if (!this.internalRecord.id) {
      this.internalRecord = this.record;
    }
  },
  created() {
    if (!this.internalRecord.id) {
      this.internalRecord = this.record;
    }
  },
  props: {
    record: {},
    recordFieldDescription: {},
    minWidth: {
      type: Number,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue() {
      this.$emit("update:record", this.internalRecord);
    },
    getEnumLabel(recordFieldDescription) {
      if (!this.internalRecord || !this.internalRecord.id) {
        return null;
      }
      var enums = recordFieldDescription.enumValues.filter(
        (e) => e.value == this.internalRecord[recordFieldDescription.name]
      );
      if (enums.length > 0) {
        return enums[0].label;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
