import ServerConnection from "../ServerConnection/ServerConnection";

class UploadImageService {
  uploadGameResultImage(gameId, file, onUploadProgress) {
    return ServerConnection.postFile("file/game/" + gameId + "/image", file, onUploadProgress);
  }

  getGameResultImage(gameId) {
    return ServerConnection.get("file/game/" + gameId + "/image");
  }

  rotateGameResultImage(gameId, rotation){
    return ServerConnection.post("file/game/" + gameId + "/image/rotate/?rotation="+rotation);
  }
}
export default new UploadImageService();