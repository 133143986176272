export const locationFieldDescriptions = [
  {
    name: "name",
    label: "Name",
    fieldType: "String",
  },
  {
    name: "street",
    label: "Straße",
    fieldType: "String",
  },
  {
    name: "plz",
    label: "PLZ",
    fieldType: "Number",
  },
  {
    name: "city",
    label: "Ort",
    fieldType: "String",
  },
  {
    name: "numberOfBoards",
    label: "Anzahl Boards",
    fieldType: "Number",
  },
  {
    name: "smokingType",
    label: "Rauchen",
    fieldType: "Enum",
    enumValues: [
      {value: "smoking", label: "Raucher"},
      {value: "nonSmoking", label: "Nichtraucher"},
      {value: "nonSmokingIfRequested", label: "Nichtraucher auf Anfrage"},
    ]
  },
  {
    name: "youthAllowed",
    label: "Jugend erlaubt",
    fieldType: "Boolean",
  },
];
export const locationLinkDescriptions = [
  {
    linkedRecordType: "team",
    recordField: "teams",
    label: "Mannschaften",
    linkTextRecordFieldName: "representation",
  },
];
