<template>
  <div>
    <div class="card">
      <div class="flex flex-column card-container green-container">
        <div class="flex align-items-center justify-content-center">
          <div
            class="flex flex-wrap card-container bg-blue-100 border-round"
            style="width: 100%; max-width: 800px; margin: 5px 1px"
          >
            <div
              class="flex-grow-1 flex align-items-center justify-content-center"
              style="width: 250px; padding: 10px"
            >
              <div class="p-float" style="text-align: left; width: 100%">
                <label
                  style="
                    margin: 5px 1px;
                    font-size: 15px;
                    text-decoration: underline black;
                  "
                >
                  Bisheriger Spielort </label
                ><br />
                <label style="font-size: 18px">{{
                  (recordModel.baseRecord.location &&
                    recordModel.baseRecord.location.representation) ??
                  "Keiner"
                }}</label>
              </div>
            </div>
            <div
              class="flex-grow-1 flex align-items-center justify-content-center"
              style="width: 300px; padding: 10px"
            >
              <div class="p-float" style="text-align: left; width: 100%">
                <label
                  style="
                    margin: 5px 1px;
                    font-size: 15px;
                    text-decoration: underline black;
                  "
                >
                  Neuer Spielort </label
                ><br />
                <Dropdown
                  v-model="selectedLocationId"
                  :options="locations"
                  optionLabel="representation"
                  optionValue="id"
                  :filter="locations.length >= 10"
                  @change="changeLocation"
                  style="width: 100%"
                />
                <small v-if="invalidState" class="p-error"
                  >Es muss ein Spielort ausgewählt werden</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerConnection from "../../../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../../../services/ServerConnection/FailureHandler";
import AbstractWizardPage from "../../AbstractWizardPage";
import Dropdown from "primevue/dropdown";
export default {
  name: "ChangeLocationPage1",
  extends: AbstractWizardPage,
  components: {
    Dropdown,
  },
  data() {
    return {
      locations: [],
      selectedLocationId: {},
      invalidState: false,
    };
  },
  created() {
    this.loadLocations();
    if (this.recordModel.baseRecord.location) {
      this.selectedLocationId = this.recordModel.baseRecord.location.id;
    }
  },
  updated() {},
  methods: {
    validate() {
      if (this.selectedLocationId) {
        return true;
      } else {
        this.invalidState = true;
        return false;
      }
    },
    loadLocations() {
      ServerConnection.get("data/location")
        .then((response) => response.data)
        .then((dataList) => {
          this.locations = dataList;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    changeLocation() {
      if (
        !this.recordModel.baseRecord.location ||
        this.selectedLocationId != this.recordModel.baseRecord.location.id
      ) {
        this.locations.forEach((location) => {
          if (location.id == this.selectedLocationId) {
            this.recordModel.newLocation = location;
            this.invalidState = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
