class WizardService {
  wizardRecordModel = [];
  wizardId = "";
  previousRoute = "";

    getWizardId(){
      return this.wizardId;
    }

    getWizardRecordModel() {
      return this.wizardRecordModel;
    }

    getWizardMetaModel(){
      return this.wizardMetaModel;
    }
    navigateToWizard (router, wizardId, wizardRecordModel, currentRoute){
      this.wizardRecordModel = wizardRecordModel;
      this.wizardId = wizardId;
      this.previousRoute = currentRoute;
      router.push("/wizard/");
    }

    navigateToPreviousSite(router){
      router.go(-2);
      if(router.options.history.state.back != "/wizard/"){
          router.go(-1);
      }
      router.replace(this.previousRoute);
    }
}
import {PrimeVueWizardServiceSymbol} from './UseWizardService';
export default {
  install: (app) => {
    app.provide(PrimeVueWizardServiceSymbol, new WizardService());
  }
}
