<template>
  <div class="card">
    <div v-if="recordModel.rematchGame" class="flex flex-column card-container">
      <div class="flex align-items-center justify-content-center" style="width: 100%">
        <div
          class="flex flex-wrap justify-content-center card-container bg-blue-100 border-round"
          style="width: 100%; max-width: 400px; margin: 5px 1px; padding: 5px"
        >
          <div class="flex flex-column card-container" style="min-height: 220px">
            <h3>Bisheriges Spiele</h3>
            <div style="margin-bottom: 10px">
              {{ recordModel.baseRecord.gameDay.numberOfGameDay }}. Spieltag ({{
                recordModel.baseRecord.date
              }})
            </div>
            <div>{{ recordModel.baseRecord.teamHome.representation }}</div>
            <div>vs.</div>
            <div>{{ recordModel.baseRecord.teamGuest.representation }}</div>
            <div style="margin-top: 10px">
              {{ recordModel.baseRecord.teamHome.location.representation }}
            </div>
          </div>
          <div
            class="flex flex-column card-container"
            style="margin-top: 30px; min-height: 200px"
          >
            <div style="margin-bottom: 10px">
              {{ recordModel.rematchGame.gameDay.numberOfGameDay }}. Spieltag ({{
                recordModel.rematchGame.date
              }})
            </div>
            <div>{{ recordModel.rematchGame.teamHome.representation }}</div>
            <div>vs.</div>
            <div>{{ recordModel.rematchGame.teamGuest.representation }}</div>
            <div style="margin-top: 10px">
              {{ recordModel.rematchGame.teamHome.location.representation }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap justify-content-center card-container bg-blue-100 border-round"
          style="width: 100%; max-width: 400px; margin: 5px 1px; padding: 5px"
        >
          <div class="flex flex-column card-container" style="min-height: 220px">
            <h3>Neue Spiele</h3>
            <div style="margin-bottom: 10px">
              {{ recordModel.baseRecord.gameDay.numberOfGameDay }}. Spieltag ({{
                recordModel.baseRecord.date
              }})
            </div>
            <div>{{ recordModel.baseRecord.teamGuest.representation }}</div>
            <div>vs.</div>
            <div>{{ recordModel.baseRecord.teamHome.representation }}</div>
            <div style="margin-top: 10px">
              {{ recordModel.baseRecord.teamGuest.location.representation }}
            </div>
          </div>
          <div
            class="flex flex-column card-container"
            style="margin-top: 30px; min-height: 200px"
          >
            <div style="margin-bottom: 10px">
              {{ recordModel.rematchGame.gameDay.numberOfGameDay }}. Spieltag ({{
                recordModel.rematchGame.date
              }})
            </div>
            <div>{{ recordModel.rematchGame.teamGuest.representation }}</div>
            <div>vs.</div>
            <div>{{ recordModel.rematchGame.teamHome.representation }}</div>
            <div style="margin-top: 10px">
              {{ recordModel.rematchGame.teamGuest.location.representation }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractWizardPage from "../../AbstractWizardPage";
import ServerConnection from "../../../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../../../services/ServerConnection/FailureHandler";
export default {
  name: "ChangeHomeGuestPage1",
  extends: AbstractWizardPage,
  data() {},
  created() {
    this.loadRematchGame();
  },
  props: {},
  methods: {
    loadRematchGame() {
      ServerConnection.get("data/game/" + this.recordModel.baseRecord.id + "/rematch")
        .then((response) => response.data)
        .then((data) => {
          this.recordModel.rematchGame = data;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
