export const associationFieldDescriptions = [
  {
    name: "bwdvNumber",
    label: "BWDV Nummer",
    fieldType: "Integer",
  },
  {
    name: "name",
    label: "Name",
    fieldType: "String",
  },
];
export const associationLinkDescriptions = [
  {
    linkedRecordType: "team",
    recordField: "teams",
    label: "Mannschaften",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "player",
    recordField: "players",
    label: "Spieler",
    linkTextRecordFieldName: "representation",
  },
];
