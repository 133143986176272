import {PrimeVueMenuServiceSymbol} from './UseMenuService';
import MenuServiceEventBus from './MenuServiceEventBus';
export default {
  install: (app) => {
      const MenuService = {
          setMainMenuItemNames: (menuItemNames) => {           
            MenuServiceEventBus.emit("mainMenuItemNames", menuItemNames)
          },
          setSidebarItems(sidebarItems){
            MenuServiceEventBus.emit("sidebarItems", sidebarItems)
          },
          setSidebarVisisble(sidebarVisible){
            MenuServiceEventBus.emit("sidebarVisible", sidebarVisible)
          }
      };
      app.provide(PrimeVueMenuServiceSymbol, MenuService);
  }
}
