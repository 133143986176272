import { inject } from 'vue';

export const PrimeVueMenuServiceSymbol = Symbol(); 

export function useMenuService() { 
    const PrimeVueMenuService = inject(PrimeVueMenuServiceSymbol);
    if (!PrimeVueMenuService) {
        throw new Error('No PrimeVue MenuService provided!'); 
    } 
    return PrimeVueMenuService; 
}
