export const gameFieldDescriptions = [
    {
      name: "date",
      label: "Datum",
      fieldType: "Date",
    },
    {
      name: "notes",
      label: "Bemerkungen",
      fieldType: "String",
    },
    {
      name: "gameStatus",
      label: "Status",
      fieldType: "Enum",
      enumValues: [
        {value: "ToPlay", label: "Zu spielen"},
        {value: "WaitingForResults", label: "Noch kein Endergebnis"},
        {value: "WaitingForImage", label: "Noch kein Bild des Ergebnisses"},
        {value: "WaitingForHomeApproval", label: "In Bestätigung durch Heim"},
        {value: "WaitingForGuestApproval", label: "In Bestätigung durch Gast"},
        {value: "WaitingForGameManagerApproval", label: "In Kontrolle durch Spielleiter"},
        {value: "InDiscussion", label: "In Abstimmung"},
        {value: "Played", label: "Gespielt"},
        {value: "PostPoned", label: "Verlegt"},
        {value: "Unrated", label: "Keine Wertung"},
        {value: "ChangeRequested", label: "Änderung beim Gegner angefragt"},
      ]
    }, 
    {
        name: "pointsHome",
        label: "Punkte Heim",
        fieldType: "Integer"

    },
    {
        name: "pointsGuest",
        label: "Punkte Gast",
        fieldType: "Integer"

    },
    {
      name: "penaltyPointsHome",
      label: "Strafpunkte Heim",
      fieldType: "Integer"

  },
  {
      name: "penaltyPointsGuest",
      label: "Strafpunkte Gast",
      fieldType: "Integer"
  },
  {
      name: "gameReportDeadline",
      label: "Abgabetermin Spielbericht",
      fieldType: "DateTime"
  }
  ];
  export const gameLinkDescriptions = [
    {
      linkedRecordType: "team",
      recordField: "teamHome",
      label: "Heim",
      linkTextRecordFieldName: "representation",
    },
    {
        linkedRecordType: "team",
        recordField: "teamGuest",
        label: "Gast",
        linkTextRecordFieldName: "representation",
      },
      {
        linkedRecordType: "gameset",
        recordField: "gameSets",
        label: "Sätze",
        linkTextRecordFieldName: "representation",
      },
      {
        linkedRecordType: "gameday",
        recordField: "gameDay",
        label: "Spieltag",
        linkTextRecordFieldName: "representation",
      },
  ];
  