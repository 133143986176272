<template>
  <div class="flex align-items-center justify-content-center">
    <div
      class="flex flex-wrap card-container bg-blue-100 border-round"
      style="max-width: 800px; margin: 5px 1px"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <div
          class="flex align-items-center justify-content-center"
          style="margin: 10px 10px 0px 10px"
        >
          <span v-if="invalidState" class="p-error">Bitte den Sieger auswählen!</span
          ><span v-else>Bitte den Sieger auswählen!</span>
        </div>
        <div
          class="flex align-items-center justify-content-center"
          style="margin-bottom: 10px"
        >
          <small>Die vorhandenen Set-Ergebnisse werden gelöscht.</small>
        </div>
        <div class="flex align-items-center justify-content-center">
          <div style="margin: 10px">
            <label style="font-size: 18px; text-decoration: underline; margin: 10px"
              >Wer ist der Sieger?</label
            >
            <SelectButton
              style="margin-top: 5px"
              v-model="winner"
              :options="winnerOptions"
              @change="updateWinner"
            />
          </div>
        </div>

        <div class="flex align-items-center justify-content-center" style="width: 100%">
          <div style="margin: 10px; width: 100%">
            <label style="font-size: 18px; text-decoration: underline"
              >Spielerleiter-Bemerkung
            </label>
            <Textarea
              v-model="recordModel.baseRecord.gameManagerNotes"
              :autoResize="false"
              style="width: 100%; height: 30vh; margin-top: 5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractWizardPage from "../../AbstractWizardPage";
import SelectButton from "primevue/selectbutton";
import Textarea from "primevue/textarea";
export default {
  name: "SetWinner",
  extends: AbstractWizardPage,
  components: {
    SelectButton,
    Textarea,
  },
  data() {
    return {
      winner: "",
      cause: "",
      invalidState: false,
    };
  },
  computed: {
    winnerOptions() {
      if (this.recordModel && this.recordModel.baseRecord) {
        return [
          "Heim (" + this.recordModel.baseRecord.teamHome.representation + ")",
          "Gast (" + this.recordModel.baseRecord.teamGuest.representation + ")",
        ];
      }
      return [];
    },
  },
  methods: {
    validate() {
      if (
        !this.winner ||
        this.winner.length <= 0 ||
        !this.recordModel.winner ||
        this.recordModel.winner.length <= 0
      ) {
        this.invalidState = true;
        return false;
      }
      this.invalidState = false;
      return true;
    },
    updateWinner() {
      if (this.recordModel) {
        if (this.winner.startsWith("Heim")) {
          this.recordModel.winner = "H";
        }
        if (this.winner.startsWith("Gast")) {
          this.recordModel.winner = "G";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
