<template>
  <sidebar
    v-model:visible="sidebarVisible"
    :showCloseIcon="false"
    style="min-width: 240px; max-width: 25%"
  >
    <Menu
      :model="sidebarItems"
      style="
        width: 90%;
        margin: 5px 1px;
        border: none;
        display: flex;
        justify-content: center;
      "
    >
      <template #item="{ item }">
        <a
          :href="item.to"
          class="p-menuitem-link"
          @click="menuItemClicked($event, item, i)"
        >
          <span
            :class="item.icon"
            style="font-size: 20px; margin-right: 10px"
            v-if="item.icon"
          ></span>
          <span
            class="p-menuitem-text"
            style="overflow: hidden; text-overflow: ellipsis"
            >{{ item.label }}</span
          >
        </a>
      </template>
    </Menu>
  </sidebar>
  <tab-menu
    :model="menuItems"
    :activeIndex="15"
    style="min-width: 200px; display: flex; justify-content: center"
  >
    <template #item="{ item }">
      <a
        :href="item.to"
        class="p-menuitem-link"
        style="display: flex; flex-direction: column; padding: 1rem 0.5rem"
        v-tooltip.bottom="getTooltip(item)"
      >
        <span :class="item.icon" style="font-size: 38px" v-if="item.icon"></span>
        <!-- <span class="p-menuitem-text">{{ item.label }}</span> -->
      </a>
    </template>
  </tab-menu>
</template>

<script>
import TabMenu from "primevue/tabmenu";
import Menu from "primevue/menu";
import Sidebar from "primevue/sidebar";
import MenuServiceEventBus from "../services/MenuService/MenuServiceEventBus";

export default {
  name: "MainMenu",
  components: {
    TabMenu,
    Menu,
    Sidebar,
  },
  data() {
    return {
      allMenuItems: [
        {
          name: "home",
          label: "Home",
          tooltip: "Hauptseite",
          icon: "pi pi-fw pi-home",
          to: "#/home",
          style: "padding: 0px 1px",
        },
        {
          name: "sidebar",
          label: "Seitenmenü",
          tooltip: "Seitenmenü",
          icon: "pi pi-fw pi-bars",
          command: () => {
            this.sidebarVisible = true;
          },
          style: "padding: 0px 1px",
        },
        {
          name: "edit",
          label: "Bearbeiten",
          tooltip: "Bearbeiten",
          icon: "pi pi-fw pi-pencil",
          style: "padding: 0px 1px",
        },
        {
          name: "save",
          label: "Speichern",
          tooltip: "Speichern",
          icon: "pi pi-fw pi-save",
          style: "padding: 0px 1px",
        },
        {
          name: "compare",
          label: "Vergleich",
          icon: "pi pi-fw pi-sync",
          tooltip: "Bild-Ergebnis-Vergleich",
          style: "padding: 0px 1px",
        },
        {
          name: "return",
          label: "Zurück",
          tooltip: "Zurück",
          icon: "pi pi-fw pi-arrow-left",
          style: "padding: 0px 1px",
        },
        {
          name: "finish",
          label: "Abschließen",
          tooltip: "Abschließen",
          icon: "pi pi-fw pi-check-square",
          style: "padding: 0px 1px",
        },
        {
          name: "hint",
          label: "Hinweisen",
          tooltip: "Hinweisen",
          icon: "pi pi-fw pi-exclamation-triangle",
          style: "padding: 0px 1px",
        },
        {
          name: "cancel",
          label: "Abbrechen",
          tooltip: "Abbrechen",
          icon: "pi pi-fw pi-times",
          style: "padding: 0px 1px",
        },
        {
          name: "settings",
          label: "Einstellungen",
          tooltip: "Einstellungen",
          icon: "pi pi-fw pi-cog",
          command: () => {
            this.$router.push("/settings");
          },
          style: "padding: 0px 1px",
        },
        {
          name: "help",
          label: "Hilfe",
          tooltip: "Hilfe",
          icon: "pi pi-fw pi-question-circle",
          command: () => {
            this.$router.push("/help");
          },
          style: "padding: 0px 1px",
        },
        {
          name: "logout",
          label: "Abmelden",
          tooltip: "Abmelden",
          icon: "pi pi-fw pi-sign-out",
          command: () => {
            this.$store.dispatch("auth/logout").then(() => this.$router.push("/login"));
          },
          style: "padding: 0px 1px",
        },
      ],
      menuItems: [],
      sidebarVisible: false,
      sidebarItems: [],
      showTooltip: true,
    };
  },
  mounted() {
    MenuServiceEventBus.on("mainMenuItemNames", this.appendNewMenuItemNames);
    MenuServiceEventBus.on("sidebarItems", this.appendSidebarItems);
    MenuServiceEventBus.on("sidebarVisible", this.handleSidebarVisible);
  },
  beforeUnmount() {
    MenuServiceEventBus.off("mainMenuItemNames");
    MenuServiceEventBus.off("sidebarItems");
    MenuServiceEventBus.off("sidebarVisible");
  },
  props: {},
  methods: {
    appendNewMenuItemNames(newMenuItemNames) {
      this.menuItems = [];
      this.allMenuItems.forEach((itemTemplate) => {
        newMenuItemNames.forEach((newItem) => {
          if (newItem == itemTemplate.name) {
            this.menuItems.push(itemTemplate);
            if (itemTemplate.name == "sidebar") {
              itemTemplate.visible = this.sidebarItems && this.sidebarItems.length > 0;
            }
          } else if (newItem.name == itemTemplate.name) {
            var copy = Object.assign({}, itemTemplate);
            copy.command = newItem.command;
            copy.icon = newItem.icon ? newItem.icon : copy.icon;
            copy.label = newItem.label ? newItem.label : copy.label;
            copy.tooltip = newItem.tooltip ? newItem.tooltip : copy.tooltip;
            if (copy.name == "sidebar") {
              copy.visible = this.sidebarItems && this.sidebarItems.length > 0;
            }
            this.menuItems.push(copy);
          }
        });
      });
    },
    appendSidebarItems(sidebarItems) {
      this.sidebarItems = sidebarItems;
      this.appendNewMenuItemNames(this.menuItems.slice(0));
    },
    handleSidebarVisible(sidebarVisible) {
      this.sidebarVisible = sidebarVisible;
    },
    menuItemClicked(event, item, index) {
      if (item.command) {
        item.command({
          originalEvent: event,
          item: item,
        });
      }
      if (item.to) {
        this.$router.push(item.to);
      }
      this.$emit("tab-change", {
        originalEvent: event,
        index: index,
      });
    },
    getTooltip(item) {
      if (this.showTooltip) {
        return item.tooltip;
      }
      return null;
    },
  },
};
</script>
