<template>
  <div class="col-md-12">
    <div class="card card-container">
      <div class="flex justify-content-center">
        <div class="card" style="min-width: 320px">
          <h3>{{ failureMessage }}</h3>
          <a href="/login">Erneut anmelden</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
export default {
  name: "Failure",
  created() {
    this.menuService.setMainMenuItemNames([]);
  },
  data() {
    return {
      menuService: useMenuService(),
    };
  },
  props: {
    failureMessage: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
