import { inject } from 'vue';

export const PrimeVueSettingsServiceSymbol = Symbol(); 

export function useSettingsService() { 
    const PrimeVueSettingsService = inject(PrimeVueSettingsServiceSymbol);
    if (!PrimeVueSettingsService) {
        throw new Error('No PrimeVue SettingsService provided!'); 
    } 
    return PrimeVueSettingsService; 
}
