export const seasonFieldDescriptions = [
    {
      name: "name",
      label: "Name",
      fieldType: "String",
    },
    {
      name: "seasonStart",
      label: "Beginn",
      fieldType: "Date",
    },
    {
      name: "seasonEnd",
      label: "Ende",
      fieldType: "Date",
    },
  ];
  export const seasonLinkDescriptions = [
    {
      linkedRecordType: "league",
      recordField: "leagues",
      label: "Ligen",
      linkTextRecordFieldName: "representation",
    },
  ];
  