export const contactpersonFieldDescriptions = [
    {
      name: "name",
      label: "Name",
      fieldType: "String",
    },
    {
      name: "email",
      label: "E-Mail",
      fieldType: "String",
    },
    {
        name: "phoneNumber",
        label: "Telefonnummer",
        fieldType: "String",
      }
  ];
  export const contactpersonLinkDescriptions = [
    {
      linkedRecordType: "team",
      recordField: "primaryTeams",
      label: "Ansprechpartner für die Manschaften",
      linkTextRecordFieldName: "representation",
    },
    {
        linkedRecordType: "team",
        recordField: "secondaryTeams",
        label: "Stellv. Ansprechpartner für die Manschaften",
        linkTextRecordFieldName: "representation",
      }
  ];
  