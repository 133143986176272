import ServerConnection from "../ServerConnection/ServerConnection";

class AuthenticationService {
  login(user) {
    return ServerConnection.post("login", user)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem('user');
  }
}
export default new AuthenticationService();