<template>
  <div
    v-if="links && links.length == 1"
    class="flex align-items-center justify-content-center"
    style="margin-top: 20px"
  >
    <span>
      <label style="margin: 10px">{{ linkDescription.label }}:</label>
      <router-link :to="link" style="border: 0px" id="name">
        {{ linkText }}
      </router-link>
    </span>
  </div>
  <div
    v-if="links && links.length > 1"
    class="flex align-items-center justify-content-center"
    style="margin-top: 20px; min-width: 300px"
  >
    <Fieldset :toggleable="collapsed" :collapsed="collapsed" style="min-width: 300px"
      ><template #legend>
        <span class="p-fieldset-toggler"></span>
        <span class="p-fieldset-legend-text">{{ linkDescription.label }}</span>
      </template>
      <div
        v-for="link in links"
        v-bind:key="link.link"
        style="text-align: left; margin: 5px 30px"
      >
        <router-link :to="link.link" style="border: 0px" id="name">
          {{ link.linkText }}
        </router-link>
      </div>
    </Fieldset>
  </div>
</template>

<script>
import Fieldset from "primevue/fieldset";
export default {
  name: "RecordLink",
  components: {
    Fieldset,
  },
  props: {
    linkDescription: {},
    record: {},
    collapsed: {
      require: false,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    links: {
      get() {
        if (this.record) {
          var linkedRecordsArray = this.record[this.linkDescription.recordField];
          if (linkedRecordsArray) {
            var links = [];
            if (Array.isArray(linkedRecordsArray)) {
              linkedRecordsArray.forEach((record) => {
                links.push({
                  linkText: record[this.linkDescription.linkTextRecordFieldName],
                  link:
                    "/recordview/" +
                    this.linkDescription.linkedRecordType +
                    "/" +
                    record.id,
                });
              });
            } else {
              links.push({
                linkText:
                  linkedRecordsArray[this.linkDescription.linkTextRecordFieldName],
                link:
                  "/recordview/" +
                  this.linkDescription.linkedRecordType +
                  "/" +
                  linkedRecordsArray.id,
              });
            }
            // links.sort(function (l1, l2) {
            //   var L1 = l1.linkText.toUpperCase();
            //   var L2 = l2.linkText.toUpperCase();
            //   if (L1 < L2) {
            //     return -1;
            //   }
            //   if (L1 > L2) {
            //     return 1;
            //   }
            //   return 0;
            // });
          }
          return links;
        }
        return null;
      },
    },
    link: {
      get() {
        if (this.links && this.links.length && this.links.length > 0) {
          return this.links[0].link;
        }
        return "";
      },
    },
    linkText: {
      get() {
        if (this.links && this.links.length && this.links.length > 0) {
          return this.links[0].linkText;
        }
        return "";
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
