export const playerFieldDescriptions = [
  {
    name: "bwdvNumber",
    label: "BWDV Nummer",
    fieldType: "Integer",
  },
  {
    name: "name",
    label: "Name",
    fieldType: "String",
  },
  {
    name: "eligibleToPlaySince",
    label: "Spielberechtigt ab",
    fieldType: "Date",
  },
  {
    name: "eligibleToPlayTill",
    label: "SpielBerechtigt bis",
    fieldType: "Date",
  },
  {
    name: "active",
    label: "Aktiv",
    fieldType: "Boolean",
  },
];
export const playerLinkDescriptions = [
  {
    linkedRecordType: "team",
    recordField: "team",
    label: "Mannschaft",
    linkTextRecordFieldName: "representation",
  },
  {
    linkedRecordType: "association",
    recordField: "association",
    label: "Verein",
    linkTextRecordFieldName: "representation",
  },
];
