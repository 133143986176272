<template>
  <div></div>
</template>

<script>
export default {
  name: "Logout",
  created() {
    this.$store.dispatch("auth/logout").then(() => this.$router.push("/login"));
  },
  updated() {
    this.$store.dispatch("auth/logout").then(() => this.$router.push("/login"));
  },
};
</script>

<style lang="scss" scoped></style>
