<template>
  <h3>Historie</h3>
  <div class="align-items-center justify-content-center" style="display: flex">
    <div>
      <DataTable
        class="p-datatable-sm"
        :value="historyEntries"
        :lazy="true"
        :loading="lazyLoading"
        :paginator="true"
        @page="onPage($event)"
        :rows="20"
        paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport JumpToPageDropdown"
        :rowsPerPageOptions="[20, 50, 100]"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}"
        responsiveLayout="stack"
        breakpoint="500px"
        :totalRecords="totalEntrySize"
        style="margin-top: 10px"
      >
        <Column field="date" header="Geändert am">
          <template #body="slotProps">
            {{ formatDateInfo(slotProps.data, "date") }}
          </template>
        </Column>
        <Column field="user" header="Geändert durch" />
        <Column field="description" header="Beschreibung" />
        <Column field="oldValue" header="Alter Wert" />
        <Column field="newValue" header="Neuer Wert" />
      </DataTable>
    </div>
  </div>
</template>

<script>
import { useMenuService } from "../services/MenuService/UseMenuService";
import ServerConnection from "../services/ServerConnection/ServerConnection";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "History",
  components: {
    DataTable,
    Column,
  },
  props: {
    recordId: {
      type: String,
      required: false,
    },
    recordType: String,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.updateMainMenu();
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.updateMainMenu();
  },
  data() {
    return {
      historyEntries: Array.from({ length: 100 }),
      totalEntrySize: null,
      lazyLoading: false,
      loadLazyTimeout: null,
    };
  },
  mounted() {
    this.loadHistoryLazy(0, 20);
  },
  methods: {
    onPage(event) {
      let { first, rows } = event;
      this.loadHistoryLazy(first, rows);
    },
    loadHistoryLazy(first, rows) {
      this.loadHistoryEntries(first, rows);
    },
    loadHistoryEntries(first, rows) {
      this.lazyLoading = true;
      var url =
        "history/" +
        this.recordType +
        (this.recordId ? "/" + this.recordId : "") +
        "?start=" +
        first++ +
        "&count=" +
        rows;
      ServerConnection.get(url)
        .then((response) => response.data)
        .then((dataList) => {
          this.totalEntrySize = dataList.totalEntrySize;
          this.historyEntries = dataList.historyEntries;
          this.lazyLoading = false;
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    formatDateInfo(data, field) {
      if (data && data[field]) {
        return data[field] + " Uhr";
      }
      return "unbekannt";
    },
    updateMainMenu() {
      const menuService = useMenuService();
      menuService.setSidebarVisisble(false);
      menuService.setMainMenuItemNames([
        "home",
        {
          name: "return",
          command: () => {
            if (window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }
          },
        },
      ]);
    },
  },
};
</script>

<style lang="css">
@media screen and (max-width: 500px) {
  /* Darken the line between the entries in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: right !important;
    border: 1px solid #555;
    border-width: 0 0 2px 0;
  }
  /* Enlarge distance between each entry in mobile mode */
  .p-datatable .p-datatable-tbody > tr > td:is(:last-child) {
    margin-bottom: 0.7rem;
    padding-bottom: 1rem;
  }
}
</style>
