export const leagueFieldDescriptions = [
    {
      name: "name",
      label: "Name",
      fieldType: "String",
    },
  ];
  export const leagueLinkDescriptions = [
    {
      linkedRecordType: "team",
      recordField: "teams",
      label: "Mannschaften",
      linkTextRecordFieldName: "representation",
    }, 
    {
      linkedRecordType: "gameday",
      recordField: "gameDays",
      label: "Spieltage",
      linkTextRecordFieldName: "representation",
    },
  ];
  